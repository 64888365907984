export default {
  setPaymentModeList(state, paymentModeList) {
    state.paymentModeList = paymentModeList
  },
  setPaymentMode(state, paymentMode) {
    state.paymentMode = paymentMode
  },
  setPaymentModeMetadata(state, metadata) {
    state.metadata = metadata
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
  setPaymentModeActivity(state, activity) {
    state.PaymentModeActivity = activity
  },
  setMetadataActivities(state, metadata) {
    state.metadataActivities = metadata
  },
}
