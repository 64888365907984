import i18n from '@src/i18n'
export default {
  searchInlist: function (copyModelList, attributes, searchTerms) {
    return copyModelList.filter((model) => {
      let search = searchTerms.length ? searchTerms.toLowerCase() : '';

      let flag = false
      attributes.forEach((val) => {
        let resp = val.field.split('.').reduce((o, i) => o[i], model)
        if(Array.isArray(resp) || typeof resp === 'object' ) {
          resp = resp?.[i18n.vueI18n.global.locale] ?? ''
        }
        switch (val.type) {
          case 'string': {
            if (resp && resp?.toLowerCase().indexOf(search.toLowerCase()) > -1) {
              flag = true;
              return;
            }
            break
          }
        }
      })
      if (flag) {
        return model
      }
    });
  },

}
