<template>
  <div
    class="scrollbar-custom flex h-full flex-col overflow-auto rounded-2xl bg-white p-5"
  >
    <div class="flex flex-shrink-0 flex-row">
      <h2 class="text-2xl font-medium">Playground</h2>
    </div>
    <div class="grid min-h-0 grid-cols-4 gap-1">
      <div>
        <SearchMarker v-model:marker="currentPlace" />
      </div>
      <div>{{ currentPlace }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DateRangeNavigator from '@c/SelectComponent/DateRangeNavigator.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import ChiftContainer from '@c/Partner/Chift/ChiftContainer.vue'
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome'
import ChurchMarker from '@/views/wiki/communes/map/components/marker/ChurchMarker.vue'
import SearchMarker from '@/views/wiki/communes/map/components/SearchMarker.vue'

export default {
  name: 'PlaygroundComponent',
  components: {
    SearchMarker,
    ChurchMarker,
    FontAwesomeIcon,
    FontAwesomeLayers,
    ChiftContainer,
    BaseShowEditInput,
    BaseButton,
    DateRangeNavigator
  },
  data() {
    return {
      currentPlace: null
    }
  },
  computed: {
    ...mapGetters({
      users: 'metadata/getUsersMetadata'
    })
  }
}
</script>
