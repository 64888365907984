<template>
  <label
    v-if="label"
    :for="target"
    class="absolute -top-2 left-2 -mt-px inline-flex flex-row flex-nowrap items-center bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
    :class="{
      'cursor-context-menu': disabled,
      [labelClass]: !!labelClass
    }"
  >
    <span class="truncate first-letter:uppercase">{{ label }}&nbsp;</span>
    <span v-if="label && required" class="text-red-600">*</span>
  </label>
</template>

<script>
export default {
  name: 'BaseLabelTop',
  props: {
    target: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
