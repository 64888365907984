<template>
  <div class="relative rounded-md border border-slate-300 bg-white shadow-lg">
    <Combobox v-model="localMarker" :disabled="disabled">
      <Float
        adaptive-width
        :offset="2"
        :auto-placement="{
          autoAlignment: true,
          allowedPlacements: ['bottom']
        }"
      >
        <div class="flex w-full flex-row overflow-hidden">
          <ComboboxInput
            autocomplete="nope"
            autofill="nope"
            class="w-full rounded-md border-0 px-3 py-2 text-sm leading-5 focus:border-0 focus:ring-0 dark:border dark:border-white dark:bg-slate-500"
            :class="{
              'bg-white': !disabled,
              'cursor-no-drop bg-gray-100': disabled
            }"
            :display-value="(val) => val?.[attributeLabel] ?? ''"
            @change="query = $event.target.value"
          />
          <div class="absolute inset-y-0 right-3 flex items-center gap-2">
            <div v-if="requesting" class="flex items-center">
              <FontAwesomeIcon
                :icon="['fal', 'spinner-third']"
                class="animate-spin"
              />
            </div>
            <ComboboxButton class="flex items-center">
              <FontAwesomeIcon :icon="['fal', 'search']" />
            </ComboboxButton>
          </div>
        </div>
        <ComboboxOptions
          class="z-50 max-h-60 w-full overflow-auto rounded-md bg-white p-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-white dark:bg-slate-500 sm:text-sm"
        >
          <template v-if="options === null">
            <div class="relative cursor-default select-none px-4 py-2">
              {{ $t('global.taper_qq') }}
            </div>
          </template>
          <template v-else-if="options.length === 0">
            <div class="relative cursor-default select-none px-4 py-2">
              {{ $t('global.no_result_found') }}
            </div>
          </template>
          <div v-for="option in options" v-else :key="option.id">
            <h2 class="text-base font-bold capitalize-first">
              {{ $t('attributes.' + option.label) }}
            </h2>
            <ComboboxOption
              v-for="result in option.results"
              v-slot="{ selected, active, disabled }"
              as="template"
              :value="result"
            >
              <li class="relative cursor-default select-none">
                <slot
                  name="option"
                  :option="option"
                  :active="active"
                  :selected="selected"
                  :disabled="disabled"
                >
                  <span
                    class="block truncate p-2 text-gray-900 dark:text-white"
                    :class="{
                      'rounded-md bg-primary-200 font-medium': active,
                      'font-normal ': !active
                    }"
                  >
                    {{ result.name?.[$i18n.locale] }}
                  </span>
                </slot>
              </li>
            </ComboboxOption>
          </div>
        </ComboboxOptions>
      </Float>
    </Combobox>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import apiClient from '@u/apiClient'
import { debounce } from '@u/debounce'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import { Float } from '@headlessui-float/vue'
import PlaceType from '@/assets/enums/config/PlaceType'

export default defineComponent({
  name: 'SearchMarker',
  components: {
    Float,
    ErrorContainer,
    ComboboxButton,
    FontAwesomeIcon,
    ComboboxOption,
    ComboboxOptions,
    ComboboxInput,
    Combobox
  },
  props: {
    marker: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    ajaxExtraParams: {
      type: Object,
      required: false,
      default: () => {}
    },
    name: {
      type: String,
      required: false,
      default: null
    },
    errors: {
      type: Object,
      required: false,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['selected-marker', 'update:marker'],
  data() {
    return {
      requesting: false,
      query: '',
      options: null
    }
  },
  watch: {
    query(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.debouncedSearch(newValue)
      }
    }
  },
  created() {
    this.debouncedSearch = debounce((terms) => {
      if (terms && terms.length >= 2) {
        this.search(terms)
      }
    }, 1000)
  },
  computed: {
    localMarker: {
      get() {
        return this.marker
      },
      set(value) {
        if (value) {
          if (
            [
              PlaceType.ADMINISTRATION_COMMUNALE,
              PlaceType.CIMETIERE,
              PlaceType.EGLISE,
              PlaceType.HOPITAL,
              PlaceType.MAISON_DE_REPOS,
              PlaceType.CREMATORIUM
            ].includes(value.type)
          ) {
            this.$router.push({
              name: 'route_place_id',
              params: {
                placeId: value.id
              }
            })
          }
          this.$emit('selected-marker', value)
        }
        this.$emit('update:marker', value)
      }
    }
  },
  methods: {
    search(terms) {
      this.requesting = true
      apiClient
        .get('/marker/search', {
          params: {
            terms: terms,
            ...this.ajaxExtraParams
          }
        })
        .then((response) => {
          this.options = response.data
        })
        .finally((_) => {
          this.requesting = false
        })
    }
  }
})
</script>
