<template>
  <template v-if="editionMode">
    <div
      class="relative rounded-md border border-slate-300 bg-white px-3 py-2 shadow-sm focus-within:border-primary-400 focus-within:ring-0 dark:border dark:border-white dark:bg-slate-500 dark:text-white"
      :class="label ? 'mt-2' : ''"
    >
      <label
        v-if="label"
        :for="name"
        class="absolute -top-2 left-2 -mt-px inline-block rounded-md bg-white px-1 text-xs font-medium text-gray-900 capitalize-first dark:bg-white"
        >{{ label }}
        <span v-if="label && required" class="text-red-600">*</span>
      </label>

      <DatePicker
        v-model:date="dateValue"
        :view="view"
        :events="events"
        :min-date="minDate"
        :max-date="maxDate"
        :extra-dots="extraDots"
        :required="required"
      />
      <button
        v-if="!required && dateValue"
        type="button"
        tabindex="-1"
        class="absolute inset-y-0 right-0 mr-3 flex items-center pl-3 text-red-600"
        @click.prevent="clearDateValue"
      >
        <FontAwesomeIcon :icon="['fal', 'times']" />
      </button>
    </div>
    <template v-if="errors">
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="form-help -mt-1 text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>
    </template>
  </template>
  <template v-else>
    <BaseShowLabel
      :label="label"
      :model-value="cDisplayedValueWhenNotEditionMode"
    />
  </template>
</template>

<script>
import moment from 'moment'
import DatePicker from '@c/addf-package/components/BaseShowEditDatePicker/Component/DatePicker.vue'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseShowEditDatePickerv2',
  components: { FontAwesomeIcon, BaseShowLabel, DatePicker },
  props: {
    date: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    view: {
      type: String,
      required: false,
      default: 'monthly'
    },
    events: {
      type: Array,
      required: false,
      default: null
    },
    disableDatePicker: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Object,
      required: false,
      default: () => {
        ;[]
      }
    },
    todayDefault: {
      type: Boolean,
      required: false,
      default: false
    },
    minDate: {
      type: String,
      required: false,
      default: null
    },
    maxDate: {
      type: String,
      required: false,
      default: null
    },
    extraDots: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: ['update:date'],
  computed: {
    cDisplayedValueWhenNotEditionMode() {
      let _moment = moment(this.dateValue)
      if (_moment.isValid()) {
        return _moment.format('DD/MM/YYYY')
      }
      return ''
    },
    dateValue: {
      set(value) {
        if (!value) {
          this.$emit('update:date', value)
        } else {
          let _moment = moment(value, 'DD/MM/YYYY')

          if (_moment.isValid()) {
            this.$emit('update:date', _moment.format('YYYY-MM-DD'))
          } else {
            this.$emit('update:date', null)
          }
        }
      },
      get() {
        if (this.date) {
          return moment(this.date).toDate()
        } else {
          return null
        }
      }
    }
  },
  mounted() {
    if (this.todayDefault && !this.dateValue) {
      this.dateValue = moment().toDate()
    }
  },
  methods: {
    clearDateValue() {
      this.dateValue = null
    }
  }
}
</script>
