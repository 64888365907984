<template>
  <MapboxMarker :lng-lat="[marker.longitude, marker.latitude]">
    <template #default>
      <button @click="handleClick">
        <ChurchMarker v-if="marker.type === 'EGL'" size="medium" />
        <CrematoriumMarker v-else-if="marker.type === 'CRE'" size="medium" />
        <CemeteryMarker v-else-if="marker.type === 'CIM'" size="medium" />
        <NursingHomeMarker v-else-if="marker.type === 'MDR'" size="medium" />
        <HospitalMarker v-else-if="marker.type === 'HOP'" size="medium" />
        <MunicipalityMarker v-else-if="marker.type === 'ADM'" size="medium" />
        <GenericMarker v-else size="medium" />
      </button>
    </template>
  </MapboxMarker>
</template>

<script>
import { MapboxMarker } from '@studiometa/vue-mapbox-gl'
import MapPopupMarker from '@/views/wiki/communes/map/components/MapPopupMarker.vue'
import ChurchMarker from '@/views/wiki/communes/map/components/marker/ChurchMarker.vue'
import CrematoriumMarker from '@/views/wiki/communes/map/components/marker/CrematoriumMarker.vue'
import CemeteryMarker from '@/views/wiki/communes/map/components/marker/CemeteryMarker.vue'
import NursingHomeMarker from '@/views/wiki/communes/map/components/marker/NursingHomeMarker.vue'
import HospitalMarker from '@/views/wiki/communes/map/components/marker/HospitalMarker.vue'
import GenericMarker from '@/views/wiki/communes/map/components/marker/GenericMarker.vue'
import MunicipalityMarker from '@/views/wiki/communes/map/components/marker/MunicipalityMarker.vue'

export default {
  name: 'MapMarker',
  components: {
    MunicipalityMarker,
    GenericMarker,
    HospitalMarker,
    NursingHomeMarker,
    CemeteryMarker,
    CrematoriumMarker,
    ChurchMarker,
    MapPopupMarker,
    MapboxMarker
  },
  props: {
    marker: {
      type: Object,
      required: true
    }
  },
  emits: ['set-center'],
  methods: {
    handleClick() {
      this.$emit('set-center', {
        longitude: this.marker.longitude,
        latitude: this.marker.latitude,
        zoom: 16
      })
      this.$router.push({
        name: 'route_place_id',
        params: {
          placeId: this.marker.id
        }
      })
    }
  }
}
</script>
