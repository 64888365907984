<template>
  <div
    class="-z-10 flex h-full flex-col gap-3 p-3 shadow-[inset_50px_0_25px_-50px_#00000075]"
  >
    <div class="shrink-0">
      <div class="flex overflow-hidden rounded border">
        <button
          class="flex-1"
          :class="{
            'bg-primary-200': currentView === 'favorite'
          }"
        >
          favoris
        </button>
        <button class="flex-1">...</button>
        <button class="flex-1">...</button>
      </div>
    </div>
    <div class="min-h-0 flex-1">
      <MapSideFavorite
        v-if="currentView === 'favorite'"
        @click:favorite-city="
          (position) =>
            $emit('change-center', {
              ...position,
              zoom: 12
            })
        "
      />
    </div>
  </div>
</template>

<script>
import MapSideFavorite from '@/views/wiki/communes/map/components/MapSideFavorite.vue'

export default {
  name: 'MapSideBar',
  components: { MapSideFavorite },
  emits: ['change-center'],
  data() {
    return {
      currentView: 'favorite'
    }
  }
}
</script>
