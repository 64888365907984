<template >
  <PageSection >

    <!--region filter-->
    <ActivityFilterHeader
        :filter="filter"
        :metadata="metadata"
    />
    <!--endregion-->

    <ActivityTable
      v-if="filteredPaymentModeActivities && filteredPaymentModeActivities.length > 0"
      :activities="filteredPaymentModeActivities"
    />
    <div
      v-else
      class="mt-10 pt-10 flex justify-center font-semibold text-gray-500"
    >
      {{ $t('global.no_activity') }}
    </div >
  </PageSection >
</template >

<script >

import ActivityTable from "@c/addf-package/components/BaseActivityTable/ActivityTable";
import PageSection from "@c/PageSection";
import DossierInProgressComponent from "@/views/wip/WorkInProgressComponent";
import { mapActions, mapGetters } from "vuex";
import ActivityFilterHeader from "@/views/settings/user/form/tabs/userActivity/ActivityFilterHeader.vue";

export default {
  name: "ThepaymentModeActivityPage",
  components: {ActivityFilterHeader, ActivityTable, PageSection, DossierInProgressComponent },
  computed: {
    ...mapGetters({
      paymentModeActivity: 'paymentMode/getPaymentModeActivity',
      metadata: 'paymentMode/getMetadataActivities'
    }) ,
    filteredPaymentModeActivities() {
      let tempActivities = this.paymentModeActivity
      if (this.filter.causer) {
        tempActivities = tempActivities.filter(
            (activity) => {
              if(this.filter.causer === "fh") {
                return activity.causer_id === null
              } else {
                return activity.causer_id === this.filter.causer
              }

            }
        )
      }

      if (this.filter.description) {
        tempActivities = tempActivities.filter(
            (activity) =>
                activity.description === this.filter.description
        )
      }
      return tempActivities
    }

  },
  beforeMount() {
    this.processFetchPaymentModeActivityByPaymentModeIDAction({ id: this.$route.params.id })
  },
  data() {
    return {
      filter: {
        causer: null,
        description: null
      }
    }
  },
  methods: { ...mapActions({ processFetchPaymentModeActivityByPaymentModeIDAction: 'paymentMode/fetchPaymentModeActivityByPaymentModeID' }) },
}
</script >

