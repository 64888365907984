import ThePaymentModeIndexPage from '@/views/settings/invoicing/PaymentMode/index/ThePaymentModeIndexPage.vue'
import ThePaymentModeFormsTabComponent from '@/views/settings/invoicing/PaymentMode/form/ThePaymentModeFormsTabComponent.vue'
import ThePaymentModeInformationPage from '@/views/settings/invoicing/PaymentMode/form/tabs/ThePaymentModeInformationPage.vue'
import TheBankAccountActivityPage from '@/views/settings/invoicing/bankAccount/form/tabs/bankAccountActivity/TheBankAccountActivityPage.vue'
import ThePaymentModeActivityPage from '@/views/settings/invoicing/PaymentMode/form/tabs/ThePaymentModeActivityPage.vue'

export const settingsPaymentModeRoutes = [
  {
    path: 'payment-mode',
    children: [
      {
        path: '',
        name: 'settings_list_payment_mode_route',
        component: ThePaymentModeIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_edit_payment_mode_route_main',
        props: true,
        component: ThePaymentModeFormsTabComponent,
        children: [
          {
            path: '',
            component: ThePaymentModeInformationPage,
            name: 'settings_edit_payment_mode_route'
          },
          {
            path: 'activities',
            component: ThePaymentModeActivityPage,
            props: true,
            name: 'settings_edit_payment_mode_activity_route'
          }
        ],
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_payment_mode_route_main',
        component: ThePaymentModeFormsTabComponent,
        children: [
          {
            path: '',
            component: ThePaymentModeInformationPage,
            name: 'settings_create_payment_mode_route'
          }
        ],
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_new_title', active: true }
          ]
        }
      }
    ]
  }
]
