<template>
  <SelectAjax
    v-model:value-object="localPerson"
    :url="'person-for-select-ajax'"
    attribute-label="title"
    :label="$t('global.concernedPerson')"
    :disabled="disabled"
  />
</template>

<script>
import { defineComponent } from 'vue'
import SelectAjax from '@c/SelectAjaxComponent/component/SelectAjax.vue'

export default defineComponent({
  name: 'SelectPerson',
  components: { SelectAjax },
  props: {
    person: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:person', 'unselect', 'select'],
  computed: {
    localPerson: {
      get() {
        return this.person
      },
      set(value) {
        this.$emit('update:person', value)
        if (value) {
          this.$emit('select', true)
        } else {
          this.$emit('unselect', true)
        }
      }
    }
  }
})
</script>
