<template>
  <div>
    <VueJsonPretty :data="data" :collapsedNodeLength="2" />
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  name: 'JsonPretty',
  components: {
    VueJsonPretty
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
