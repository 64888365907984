<template>
  <BaseButton
    :title="$t('button.edit')"
    size="extra-small"
    @click="modalOpened = true"
  />
  <BaseCenteredModal
    v-model:is-modal-open="modalOpened"
    :title="item.name[$i18n.locale]"
    size="medium"
  >
    <template v-if="locationId" #subtitle>
      {{ $t('attributes.place') }}: {{ selectedLocation?.name ?? '----' }}
    </template>
    <template #body>
      <div class="flex w-full flex-col gap-3">
        <div v-if="!locationId">
          <SelectStorageLocation
            v-model="selectedLocationId"
            error-key="location"
            :edition-mode="true"
          />
        </div>
        <div class="flex flex-col gap-2 rounded border p-3 pt-2">
          <h6 class="text-base font-semibold capitalize-first">
            {{ $t('global.stock') }}
          </h6>
          <div class="grid grid-cols-1 gap-2 md:grid-cols-2">
            <div>
              <BaseShowEditInput
                v-model="quantityAvailable"
                :disabled="!editQuantityAvailable"
                :min="0"
                type="number"
              />
            </div>
            <div class="flex flex-row justify-end gap-2">
              <BaseButton
                v-if="editQuantityAvailable"
                :title="$t('button.save')"
                @click="addItemsStock"
              />
              <BaseButton
                :title="
                  $t(editQuantityAvailable ? 'button.cancel' : 'button.edit')
                "
                theme-color="bg-secondary text-secondary-contrast"
                @click="handleEditCancel"
              />
            </div>
            <div v-if="editQuantityAvailable" class="col-span-2">
              <InfoPanel
                v-if="originalQuantityAvailable < quantityAvailable"
                :title="'Info'"
                >augmentez le stock créera des items stocks unique avec leur
                propre identifiant.</InfoPanel
              >
              <InfoPanel
                v-else-if="originalQuantityAvailable > quantityAvailable"
                type="warning"
                :title="'Attention'"
              >
                diminuez le stock, mettra des éléments en avarie.
              </InfoPanel>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2 rounded border p-3 pt-2">
          <h6 class="text-base font-semibold capitalize-first">
            {{ $t('global.order') }}
          </h6>
          <div v-if="supplier_error" class="border border-orange-600 bg-orange-50 text-orange-600 p-2 flex gap-x-2">
            <FontAwesomeIcon :icon="['fal', 'triangle-exclamation']" class="mt-0.5" />
            <div>Il n'ya pas de fournisseur définit par défaut sur cet article. Veuillez définir un fournisseur dans les paramètres de l'article
              <FontAwesomeIcon :icon="['fas', 'diamond-turn-right']" class="ml-1 text-theme-primary cursor-pointer" @click="$router.push({ name: 'settings_edit_item_route', params: { id: localItem.id } })"/>
            </div>
          </div>
          <div v-if="localItem" class="grid grid-cols-1 gap-2 md:grid-cols-2">
            <div
              v-for="supplier_order in localItem.supplier_orders"
              :key="supplier_order.id"
              class="cursor-pointer rounded border p-2 text-left"
              :class="[
                !newSupplierOrder &&
                selectedSupplierOrderId === supplier_order.id
                  ? 'border-primary shadow'
                  : ''
              ]"
              @click="() => handleButtonSelectExistingOrder(supplier_order, supplier_order.id)"
            >
              <h3 class="font-semibold">
               {{ suppliers?.find((supplier) => supplier.id === supplier_order.recipient_id)?.name ?? supplier_order.reference }}
              </h3>
              <p class="text-xs"> {{ supplier_order.delivery_model?.full_name }}</p>
            </div>
            <div
              class="flex cursor-pointer items-center justify-center rounded p-2"
              :class="[
                newSupplierOrder
                  ? 'border border-primary shadow'
                  : 'border-2 border-dashed'
              ]"
              @click="handleButtonNewOrder"
            >
              <h3 class="font-semibold">
                {{ $t('button.add_order') }}
              </h3>
            </div>
          </div>
          <div
            v-if="orderSelected"
            class="mt-4 grid grid-cols-1 gap-2 md:grid-cols-2"
          >
            <div>
              <BaseShowEditInput
                v-model="quantityToOrder"
                :min="0"
                type="number"
                :label="$t('attributes.quantity')"
              />
            </div>
            <div v-if="newSupplierOrder">
              <SelectStorageLocation
                v-model="newSupplierOrderLocationId"
                error-key="location"
                :edition-mode="true"
                required
              />
            </div>
            <div class="col-span-2 flex justify-end">
              <BaseButton
                :title="$t('button.order')"
                :disabled="!canOrder"
                @click="handleOrderItem"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseCenteredModal>
</template>
<script>
import BaseCenteredModal from '@c/addf-package/components/modal/BaseCenteredModal.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import SelectStorageLocation from '@c/InitialStateFormComponent/SelectStorageLocation.vue'
import { mapActions, mapGetters } from 'vuex'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import InfoPanel from '@ui/InfoPanel.vue'
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'EditStockItemModal',
  components: {
    FontAwesomeIcon,
    InfoPanel,
    BaseShowEditInput,
    SelectStorageLocation,
    BaseButton,
    BaseCenteredModal
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    locationId: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['update-stock-item', 'refresh-stock'],
  data() {
    return {
      localItem: null,
      modalOpened: false,
      selectedLocationId: null,
      editQuantityAvailable: false,
      originalQuantityAvailable: 0,
      quantityAvailable: 0,
      newSupplierOrder: false,
      newSupplierOrderLocationId: null,
      selectedSupplierOrderId: null,
      selectedSupplierOrder: null,
      quantityToOrder: 0,
      supplier_error: false
    }
  },
  computed: {
    ...mapGetters({
      locations: 'metadata/getLocationsMetadata',
      sites: 'metadata/getSitesMetadata',
      funeralHomes: 'metadata/getFuneralHomesMetadata',
      suppliers: 'metadata/getSuppliersMetadata'
    }),
    selectedLocation() {
      return (
        this.locations.find((location) => location.id === this.locationId)
      )
    },
    orderSelected() {
      return this.newSupplierOrder || this.selectedSupplierOrderId !== null
    },
    canOrder() {
      return (
        (this.newSupplierOrder &&
          this.quantityToOrder > 0 &&
          this.newSupplierOrderLocationId) ||
        (!this.newSupplierOrder &&
          this.selectedSupplierOrderId &&
          this.quantityToOrder > 0)
      )
    }
  },
  watch: {
    modalOpened(newValue, oldValue) {
      if (newValue) {
        this.getItemForStockEdit()
      }
    },
    // selectedLocationId(newValue, oldValue) {
    //   console.log('change', this.selectedLocationId)
    // }
  },
  mounted() {
    if (this.locationId ) {
      this.selectedLocationId = this.locationId
    }
  },
  methods: {
    ...mapActions({
      processFetchItemStockAction: 'item/fetchItemStock',
      processUpdateItemStockListAction: 'item/updateItemStockList',
      processGetStockableItemAction: 'stock/getStockableItem',
      processStoreSupplierOrderAction: 'supplierOrder/storeSupplierOrder',
      processUpdateSupplierOrderAction: 'supplierOrder/updateSupplierOrder',
      processAddItemsToSupplierOrderAction: 'supplierOrder/addItemsToSupplierOrder'
    }),
    getItemForStockEdit() {
      this.processFetchItemStockAction({
        item_id: this.item.id
      }).then((response) => {
        console.log(this.locationId)
        this.localItem = response.item
        this.originalQuantityAvailable = this.quantityAvailable =
          response.item.summary_stock_by_location?.[this.locationId]?.[
            'AVAILABLE'
          ] ?? 0
      })
    },
    addItemsStock() {
      this.processUpdateItemStockListAction({
        item_id: this.localItem.id,
        location: this.locationId,
        quantity: this.quantityAvailable,
        purchase_price: this.localItem.default_purchase_price_htva
      }).then((response) => {
        this.processGetStockableItemAction().then((response) => {
          this.$emit('update-stock-item', response.items)
          this.modalOpened=false
        })

      })
    },
    handleEditCancel() {
      if (this.editQuantityAvailable) {
        this.quantityAvailable = this.originalQuantityAvailable
        this.editQuantityAvailable = false
      } else {
        this.editQuantityAvailable = true
      }
    },
    handleButtonNewOrder() {
      this.selectedSupplierOrderId = null
      this.selectedSupplierOrder = null
      this.newSupplierOrder = !this.newSupplierOrder
    },
    handleButtonSelectExistingOrder(supplier_order, supplier_order_id) {
      this.newSupplierOrder = false
      if (this.selectedSupplierOrderId === supplier_order_id) {
        this.selectedSupplierOrderId = null
        this.selectedSupplierOrder = null
        return
      }
      this.selectedSupplierOrderId = supplier_order_id
      this.selectedSupplierOrder = supplier_order
    },
    handleOrderItem() {
      if (this.newSupplierOrder) {
        if (!this.localItem.supplier_id) {
          this.supplier_error = true;
          return;
        }
        const newSupplierOrderLocation = this.locations.find(location => location.id === this.newSupplierOrderLocationId)
        const site = this.sites.find(site => site.id === newSupplierOrderLocation?.site_id)
        const funeral_home = this.funeralHomes.find(fh => fh.id === site?.funeral_home_id)
        const supplierOrder = {
          emission_date: moment().format(),
          funeral_home_id: funeral_home?.id,
          company_id: funeral_home?.company_id,
          status: 'DRA',
          delivery_place_type: 'SFU',
          delivery_place_id: this.newSupplierOrderLocationId,
          recipient_type: 'App\\Models\\Supplier',
          recipient_id: this.localItem.supplier_id,
          supplierOrderItems: [
            {
              item_id: this.localItem.id,
              quantity: parseInt(this.quantityToOrder),
              supplier_order_id: null,
              price_htva: this.localItem.default_price_htva,
              name: this.localItem.name_index,
              price_tvac: this.localItem.default_price_tvac,
              price_tva_value: this.localItem.default_price_tva_value,
              tva: this.localItem.default_tva,
              position: 1
            }
          ],
        }

        this.processStoreSupplierOrderAction({
          supplier_order: supplierOrder
        }).then((response) => {
          this.$h.toastSuccess('Un bon de commande fournisseur pour cet article a été créé')
          this.modalOpened = false
          this.newSupplierOrderLocationId = null
          this.$emit('refresh-stock')
          this.newSupplierOrder = false
        })

      } else {
        const supplierOrderItem = this.selectedSupplierOrder.supplier_order_items?.find(item => item.item_id === this.localItem.id)
        if (supplierOrderItem) {
          supplierOrderItem.quantity += parseInt(this.quantityToOrder)
          this.selectedSupplierOrder.supplierOrderItems = this.selectedSupplierOrder.supplier_order_items
          this.processUpdateSupplierOrderAction({
            supplier_order_id: this.selectedSupplierOrder.id,
            supplier_order: this.selectedSupplierOrder
          }).then((response) => {
            this.$h.toastSuccess('Cette commande a bien été mise à jour')
            this.$emit('refresh-stock')
            this.modalOpened=false
            this.selectedSupplierOrderId = null
            this.selectedSupplierOrder = null
            this.quantityToOrder = null
          })

        } else {
          this.processAddItemsToSupplierOrderAction({
            supplier_order_id: this.supplierOrder.id,
            params: {
              items: [
                {
                  id: this.localItem.id,
                  quantity: parseInt(this.quantityToOrder)
                }
              ]
            }
          }).then((response) => {
              this.$h.toastSuccess('Article ajouté à la commande')
              this.$emit('update-stock-item', response.items)
              this.modalOpened=false
              this.selectedSupplierOrderId = null
              this.selectedSupplierOrder = null
              this.quantityToOrder = null
          })
        }
      }
    }

  }
}
</script>

<style scoped></style>
