import CheckMutations from './check/mutations'
import InvoiceItemMutation from './invoiceItem/mutations'
import PaymentMutations from './payment/mutations'
import PersonMutations from './person/mutations'
import ExportMutations from './export/mutations'
import InvoiceSendRecordMutations from './invoiceSendRecord/mutations'

export default {
  ...CheckMutations,
  ...InvoiceItemMutation,
  ...PaymentMutations,
  ...PersonMutations,
  ...ExportMutations,
  ...InvoiceSendRecordMutations,

  setFilters(state, filters) {
    state.filters = filters
  },
  setInvoiceDossierFuneralHomeId(state, funeral_home_id) {
    state.invoice.dossier.funeral_home_id = funeral_home_id
  },

  setInvoiceDossier(state, dossier) {
    state.invoice.dossier = dossier
  },

  setInvoicePerson(state, person) {
    state.invoice.person = person
  },

  // region invoice
  setInvoiceIndex(state, invoiceList) {
    state.invoiceIndex = invoiceList
  },
  setInvoice(state, invoice) {
    // if (!('id' in invoice) || invoice.id === null) {
    //   invoice.reference = state.invoiceMetadata.next_reference['1']
    //   invoice.number = state.invoiceMetadata.next_number['1']
    // }
    state.invoice = invoice
  },
  setInvoiceAtIndex(state, payload) {
    state.invoiceIndex[payload.index] = payload.invoice
  },
  setInvoiceMetadata(state, metadata) {
    state.invoiceMetadata = metadata
  },
  setInvoiceActivity(state, activity) {
    state.invoiceActivity = activity
  },
  setMetadataActivities(state, metadata) {
    state.metadataActivities = metadata
  },
  setSendInvoiceData(state, sendInvoiceData) {
    state.sendInvoiceData = sendInvoiceData
  },
  setSendInvoiceMessageText(state, message) {
    state.sendInvoiceData.messageConverted = message
  },
  incrementInvoiceItemQuantityByItemId(state, item_id) {
    state.invoice.invoice_items.find(
      (invoice_item) => invoice_item.item_id === item_id
    ).quantity++
  },
  addInvoiceItemToInvoice(state, future_invoice_item) {
    state.invoice.invoice_items.push(future_invoice_item)
  },
  setInvoiceSupplierOrderAddressProperty(state, payload) {
    if (!('supplier_order_property' in state.invoice))
      state.invoice.supplier_order_property = { delivery_model: { name: null } }

    state.invoice.supplier_order_property.delivery_place_id = payload?.id
    state.invoice.supplier_order_property.delivery_place_type = payload?.type
    state.invoice.supplier_order_property.delivery_model.name = payload?.label
  },
  setInvoiceInvoiceableID(state, invoiceable_id) {
    state.invoice.invoiceable_id = invoiceable_id
  },
  setInvoiceInvoiceable(state, invoiceable) {
    state.invoice.invoiceable = invoiceable
  },

  resetInvoice(state) {
    state.invoice = {
      dossier: {},
      contact: {}
    }
  },

  // endregion

  // region invoiceItem
  setInvoiceItem(state, invoiceItem) {
    state.invoiceItem = invoiceItem
  },
  // endregion

  // region payment
  setPayment(state, payment) {
    state.payment = payment
  },
  setPaymentMetadata(state, metadata) {
    state.paymentMetadata = metadata
  },
  alterPayment(state, payload) {
    state.payment[payload.attribute] = payload.value
  },
  // endregion

  addOnlineMembers(state, payload) {
    if (
      !state.online_members.some(
        (online_member) => online_member.id === payload.id
      )
    ) {
      state.online_members.push(payload)
    }
  },
  removeOnlineMembers(state, payload) {
    state.online_members = state.online_members.filter(
      (online_member) => online_member.id !== payload.id
    )
  },
  resetOnlineMembers(state) {
    state.online_members = []
  }
}
