import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import './components/addf-package/index.css'
import mitt from 'mitt'
import VueScrollTo from 'vue-scrollto'
import { helper } from './utils/helper'
import Pusher from './plugins/pusher'
import Functionality from './plugins/functionality'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import { abilitiesPlugin } from '@casl/vue'
import ability from './utils/ability'

import fontawesomeComponent from './components/baseComponents/fontawesome'
import ADDFComponents from './components/addf-package/components'

import utils from './utils'
import './libs'
import './assets/sass/app.scss'

import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import VueShepherdPlugin from 'vue-shepherd'
import TransPlugin from '@/plugins/trans.plugin'

const emitter = mitt()

// import RayPlugin from 'vue-ray'

if (!Object.hasOwn) {
  Object.hasOwn = function (obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop)
  }
}

const user = store.getters.getAuth
Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_KEY,
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ['production', 'staging'],
  enabledBreadcrumbTypes: ['error', 'navigation', 'request', 'user'],
  appVersion: import.meta.env.VITE_APP_VERSION,
  releaseStage: import.meta.env.MODE,
  user: {
    id: user?.id,
    name: user?.full_name,
    email: user?.email
  }
})

const bugsnagVue = Bugsnag.getPlugin('vue')

i18n.setup()

const app = createApp(App)
  // .use(baseComponents)
  .use(i18n.vueI18n)
  .use(store)
  .use(router)
  .use(bugsnagVue)
  .use(VueShepherdPlugin)
  .use(VueScrollTo)
  .use(Pusher)
  .use(Functionality)
  .use(TransPlugin)
  .use(abilitiesPlugin, ability, {
    useGlobalProperties: true
  })

ADDFComponents(app)
fontawesomeComponent(app)
utils(app)
helper.initBrand(app)

app.config.globalProperties.emitter = emitter

app.config.errorHandler = (err, instance, info) => {
  console.log(err)
  console.log(instance)
  console.log(info)
  helper.toastInfo(
    "Un erreur s'est produite. L'équipe support a été notifiée de cette erreur.",
    null,
    'toastErrorHandler'
  )
}

app.mount('#app')
