<template>
  <div class="min-w-0 flex-1">
    <SettingsPageFull>
      <template #title>
        <div class="flex space-x-3">
          <span class="text-3xl font-bold capitalize-first">{{ $t('navigation.content_payment_mode_title') }}</span>
        </div>
      </template>

      <template #cta>
        <BaseButton
          v-if="$can('manage_content')"
          :title="$t('button.add_new_m', { var: $t('global.payment_mode') })"
          color="primary"
          icon="plus"
          @click="$router.push({ name: 'settings_create_payment_mode_route' })"
        />
      </template>

      <template #body>
        <ToastUndoComponent
          :text="$t('toasts.deleted_m', { var: $t('global.payment_mode') })"
        />
        <BaseTableCardsComponent
          v-if="paymentModeList && metadata"
          :entries="paymentModeList"
          :actions="cActions"
          :storage-name="'dt-payment-mode'"
          :select-entry="
            (payment_mode) =>
              $router.push({
                name: 'settings_edit_payment_mode_route',
                params: { id: payment_mode.id }
              })
          "
          :table-metadata="metadata"
          base-card-title="name"
        >
          <template #table-cell="{ index, data: payment_mode, column }">
            <td
              v-if="index === 0"
              class="sticky top-0 z-10 border-b border-gray-200 bg-gray-50 bg-opacity-75 text-left text-sm font-semibold text-gray-900"
              :class="index === 0 ? 'py-3.5 pl-4 pr-3 sm:pl-6' : 'px-3 py-3.5'"
            >
              <div class="flex flex-row items-center gap-2">
                <div>
                  <router-link
                    :to="{
                      name: 'settings_edit_payment_mode_route',
                      params: { id: payment_mode.id }
                    }"
                    class="font-bold"
                  >
                    {{ payment_mode.denomination_index }}
                  </router-link>
                </div>
              </div>
            </td>
          </template>
          <template #card-details="{ entry: payment_mode }">
            <div class="flex-gap-1 px-5 pb-5 text-center lg:text-left">
              <div
                v-if="payment_mode.denomination_index"
                class="mb-2 flex cursor-default items-center justify-center text-gray-600 lg:justify-start"
              >
                <span>{{ payment_mode.denomination_index }}</span>
              </div>
            </div>
          </template>
        </BaseTableCardsComponent>
      </template>
    </SettingsPageFull>
  </div>
</template>

<script>
import BaseTableCardsComponent from '@c/BaseTableCardsComponent'
import SettingsPageFull from '@c/SettingsPageFull'
import cash from 'cash-dom/dist/cash'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastUndoComponent from '@c/addf-package/components/BaseToast/ToastUndoComponent.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'ThePaymentModeIndexPage',
  components: {
    BaseButton,
    ToastUndoComponent,
    BaseTableCardsComponent,
    SettingsPageFull
  },
  computed: {
    ...mapGetters({
      paymentModeList: 'paymentMode/getPaymentModeList',
      metadata: 'paymentMode/getPaymentModeMetadata'
    }),
    cActions() {
      return [
        {
          label: this.$t('button.edit'),
          icon: 'edit',
          bgColor: 'bg-gradient-to-b from-gray-100 to-gray-200',
          fgColor: 'text-gray-700',
          clickFunction: (payment_mode) =>
            this.redirectToEditableModelPage(payment_mode.id)
        },
        {
          label: this.$t('button.delete'),
          icon: 'trash',
          bgColor: 'bg-gradient-to-b from-red-600 to-red-700',
          fgColor: 'text-white',
          clickFunction: (payment_mode) =>
            this.deletePaymentMode(payment_mode.id)
        }
      ]
    }
  },
  methods: {
    ...mapMutations({
      processSetEditionModeMutation: 'paymentMode/setEditionMode'
    }),
    ...mapActions({
      processFetchPaymentModeListAction: 'paymentMode/fetchPaymentModeList',
      processDeletePaymentModeById: 'paymentMode/deletePaymentModeById',
      processUndoDeletePaymentModeById: 'paymentMode/undoDeletePaymentModeById'
    }),
    redirectToEditableModelPage(id) {
      this.processSetEditionModeMutation(true)
      this.$router.push({
        name: 'settings_edit_payment_mode_route',
        params: { id: id }
      })
    },
    deletePaymentMode(id) {
      this.processDeletePaymentModeById({
        id: id
      }).then((response) => {
        this.$h.toastAction(
          'delete',
          this.$t('toasts.deleted_m', { var: this.$t('global.payment_mode') }),
          null,
          [
            {
              title: this.$t('button.cancel'),
              class: 'text-red-700',
              action: () => {
                this.undoDeletePaymentMode(id)
              }
            }
          ]
        )
      })
    },
    undoDeletePaymentMode(id) {
      console.log('undo delete')
      this.processUndoDeletePaymentModeById({
        id: id,
      }).then((response) => {
        this.$h.toastSuccess(
          this.$t('toasts.reseted_m', { var: this.$t('global.payment_mode') })
        )
      })
    }
  },
  async mounted() {
    await this.processFetchPaymentModeListAction()
  }
}
</script>
