import axios from 'axios'
import store from '../store'
import Bugsnag from '@bugsnag/js'
import { helper } from '@u/helper'

const ApiPort = helper.getDevPort()
const ApiUri = '/api/'

export const baseURL =
  process.env.NODE_ENV === 'development'
    ? `http://${window.location.hostname}:${ApiPort}${ApiUri}`
    : ApiUri

const apiClient = axios.create({
  baseURL
})

apiClient.interceptors.request.use(
  (config) => {
    if (
      localStorage.getItem('token') &&
      localStorage.getItem('expiration') &&
      new Date() > new Date(localStorage.getItem('expiration'))
    ) {
      localStorage.removeItem('token')
      localStorage.removeItem('expiration')
      window.location.href = '/connect'
    }
    if (localStorage.getItem('token')) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response) {
      let response_data = error.response.data
      switch (error.response.status) {
        case 401:
          store.commit('auth/setErrors', response_data.errors)
          window.location.href = '/connect'
          break
        case 403:
          helper.toastWarning(
            response_data.errors?.permissions ??
              response_data.errors?.roles ??
              'You are not authorised to perform this action.'
          )
          return Promise.reject(error)
        case 404:
        case 408:
          helper.toastDanger(
            response_data?.errors?.message ?? 'something went wrong'
          )
          return Promise.reject(error)
        case 422:
          helper.toastWarning(
            response_data?.message ??
              'des erreurs sont présentes dans le formulaire'
          )
          store.commit('auth/setErrors', response_data.errors)
          return Promise.reject(error)
        case 429:
          helper.toastWarning(response_data?.errors?.throttle)
          return Promise.reject(error)
        case 500:
          helper.toastDanger('Erreur serveur')
          store.commit('auth/setErrors', { 500: [] })
          return Promise.reject(error)
        default:
          Bugsnag.notify(error)
          return Promise.reject(error)
      }
    } else {
      helper.toastDanger('erreur serveur')
      return Promise.reject(error)
    }
  }
)

export default apiClient
