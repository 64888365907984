<template>
  <PartnerContainer
    :partners-logo="PartnersLogo.chift"
    :loading="loading"
    :scrollable="scrollable"
    :background-colored="backgroundColored"
    tint="chift"
    :size="size"
    :class="$attrs.class"
  >
    <template #top-left-cta>
      <slot name="top-left-cta" />
    </template>
    <template #default>
      <slot name="default" />
    </template>
    <template #top-right-cta>
      <slot name="top-right-cta" />
    </template>
    <template #bottom-right-cta>
      <slot name="bottom-right-cta" />
    </template>
    <template #bottom-left-cta>
      <slot name="bottom-left-cta" />
    </template>
  </PartnerContainer>
</template>

<script>
import PartnersLogo from '@/assets/enums/config/PartnersLogo'
import LoadingComponent from '@c/TheLoadingComponent.vue'
import PartnerContainer from '@c/Partner/PartnerContainer.vue'

export default {
  name: 'ChiftContainer',
  components: { PartnerContainer, LoadingComponent },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium'
    },
    scrollable: {
      type: Boolean,
      require: false,
      default: false
    },
    backgroundColored: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  computed: {
    PartnersLogo() {
      return PartnersLogo
    }
  }
}
</script>
