<template>
  <button
    type="button"
    class="btn flex active:scale-95"
    :class="[...buttonClasses, $attrs.class]"
    :disabled="disabled"
    :title="title"
  >
    <span class="leading-6 empty:hidden">
      <FontAwesomeIcon
        v-if="displayLoader"
        :icon="['fal', 'spinner-third']"
        spin
        :size="iconSizeClass"
      />
      <FontAwesomeIcon
        v-else-if="icon"
        :icon="[iconType, icon]"
        :size="iconSizeClass"
      />
    </span>
    <span
      v-if="title && !hideTitle"
      class="inline-block leading-6 capitalize-first"
    >
      {{ title }}
    </span>
    <slot name="content_inside" />
  </button>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseButton',
  components: { FontAwesomeIcon },
  props: {
    iconType: {
      type: String,
      required: false,
      default: 'fal'
    },
    className: {
      type: String,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    hideTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    linkTarget: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    },
    color: {
      type: String,
      required: false,
      default: '',
      validator: function (value) {
        const possibleTypes = [
          '',
          'primary',
          'secondary',
          'success',
          'warning',
          'danger',
          'dark',
          'info'
        ]
        return possibleTypes.indexOf(value) !== -1
      }
    },
    themeColor: {
      type: String,
      required: false,
      default: null
    },
    isOutline: {
      type: Boolean,
      required: false,
      default: false
    },
    isRounded: {
      type: Boolean,
      required: false,
      default: false
    },
    isSoft: {
      type: Boolean,
      required: false,
      default: false
    },
    isElevated: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: function (value) {
        return (
          ['extra-small', 'small', 'medium', 'large', 'extra-large'].indexOf(
            value
          ) !== -1
        )
      }
    },
    scopedClasses: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'button',
      required: false,
      validator: function (value) {
        return ['button', 'submit'].indexOf(value) !== -1
      }
    },
    cursor: {
      type: String,
      required: false,
      default: 'pointer',
      validator: (val) =>
        [
          'pointer',
          'default',
          'move',
          'text',
          'help',
          'not-allowed',
          'none',
          'context-menu',
          'grabbing'
        ].indexOf(val) !== -1
    },
    textColor: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
    inverseIconOrder: {
      type: Boolean,
      required: false,
      default: false
    },
    displayLoader: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    hasTitle() {
      return this.title && this.title.length
    },
    buttonTextColorClass() {
      if (this.themeColor && this.themeColor.indexOf('text-') > -1) {
        return ''
      }
      if (this.textColor) {
        return `text-${this.textColor}`
      }
      if (this.isOutline) {
        return `hover:text-${this.color} text-gray-500`
      }
      return this.color === 'primary' ? ' text-contrast-primary' : ''
    },
    buttonCursorClass() {
      return `cursor-${this.cursor}`
    },
    buttonRoundedClass() {
      return this.isRounded ? 'rounded-full' : ''
    },
    buttonTypeClass() {
      if (this.themeColor) {
        return `btn ${this.themeColor}`
      }
      if (this.isOutline) {
        return `btn-outline-${this.color}`
      }
      if (this.isSoft) {
        return `btn-${this.color}-soft`
      }
      return this.color === 'primary' ? 'btn bg-primary' : `btn-${this.color}`
    },
    buttonSizeClass() {
      switch (this.size) {
        case 'extra-small':
          return 'px-2 py-0 gap-1 text-xs'
        case 'small':
          return 'px-2.5 py-0.5 gap-2'
        case 'large':
          return 'px-4 py-3 gap-3'
        case 'extra-large':
          return 'px-5 py-4 gap-3 text-base'
        case 'medium':
        default:
          return 'px-3 py-1.5 gap-3'
      }
    },
    iconSizeClass() {
      switch (this.size) {
        case 'extra-small':
          return 'sm'
        case 'large':
          return 'xl'
        case 'extra-large':
          return 'xl'
        case 'small':
        case 'medium':
        default:
          return undefined
      }
    },
    buttonIconPosition() {
      return this.inverseIconOrder ? 'flex-row' : 'flex-row-reverse'
    },
    buttonElevationClass() {
      return this.isElevated ? 'shadow-md' : ''
    },
    buttonHeight() {
      if (this.hasTitle) return ''

      switch (this.size) {
        case 'extra-small':
          return 'h-6 px-6'
        default:
          return 'h-8 px-8'
      }
    },
    buttonClasses() {
      return [
        this.className,
        this.buttonTypeClass,
        this.buttonRoundedClass,
        this.buttonElevationClass,
        this.buttonSizeClass,
        this.scopedClasses,
        this.buttonCursorClass,
        this.buttonTextColorClass,
        this.buttonIconPosition
        // this.buttonHeight
      ]
    }
  }
}
</script>
