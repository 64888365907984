<template>
  <div
    :data-tint="tint"
    class="flex flex-col bg-primary/25"
    :class="[$attrs.class, outerElementClass]"
  >
    <div class="flex shrink-0 flex-row gap-3">
      <div
        v-if="$slots['top-left-cta']"
        class="flex shrink-0 flex-row items-center empty:hidden"
      >
        <slot name="top-left-cta"></slot>
      </div>
      <div class="flex flex-1 items-center">
        <img :src="partnersLogo" :class="logoSize" />
      </div>
      <div v-if="$slots['top-right-cta']" class="shrink-0 empty:hidden">
        <slot name="top-right-cta"></slot>
      </div>
    </div>
    <div class="relative min-h-0 flex-1">
      <div
        v-if="loading"
        class="absolute inset-0 z-20 border-primary/50 bg-primary-300/25"
        :class="innerContainerClass"
      >
        <LoadingComponent size="small" :label="'loading data'" />
      </div>
      <div
        id="partner-container-default"
        class="flex flex-col gap-3"
        :class="[
          innerContainerClass,
          scrollable ? 'absolute inset-0 overflow-y-auto' : '',
          backgroundColored ? 'bg-white/75' : 'bg-white'
        ]"
      >
        <slot name="default" />
      </div>
    </div>
    <div
      v-if="$slots['bottom-left-cta'] || $slots['bottom-right-cta']"
      class="flex shrink-0 flex-row gap-1 empty:hidden"
    >
      <div class="flex flex-1 flex-row items-center">
        <slot name="bottom-left-cta"></slot>
      </div>
      <div class="shrink-0 empty:hidden">
        <slot name="bottom-right-cta"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from '@c/TheLoadingComponent.vue'
import PartnersLogo from '../../assets/enums/config/PartnersLogo'

export default {
  name: 'PartnerContainer',
  components: { LoadingComponent },
  props: {
    partnersLogo: {
      type: String,
      required: false,
      default: null
    },
    tint: {
      type: String,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium'
    },
    scrollable: {
      type: Boolean,
      require: false,
      default: false
    },
    backgroundColored: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  computed: {
    PartnersLogo() {
      return PartnersLogo
    },
    outerElementClass() {
      switch (this.size) {
        case 'extra-small':
          return 'rounded p-1.5 gap-1.5'
        case 'small':
          return 'rounded p-2 gap-2'
        case 'medium':
          return 'rounded-md p-3 gap-3'
        case 'large':
          return 'rounded-lg p-4 gap-4'
        case 'extra-large':
          return 'rounded-xl p-[1.125rem] gap-[1.125rem]'
      }
    },
    innerContainerClass() {
      switch (this.size) {
        case 'extra-small':
          return 'rounded p-1.5 '
        case 'small':
          return 'rounded p-2'
        case 'medium':
          return 'rounded-md p-3'
        case 'large':
          return 'rounded-lg p-4'
        case 'extra-large':
          return 'rounded-xl p-[1.125rem]'
      }
    },
    logoSize() {
      switch (this.size) {
        case 'extra-small':
          return 'h-4'
        case 'small':
          return 'h-5'
        case 'medium':
          return 'h-6'
        case 'large':
          return 'h-8'
        case 'extra-large':
          return 'h-10'
      }
    }
  }
}
</script>
