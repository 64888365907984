const TheRoleIndexPage = () =>
  import(
    /* webpackChunkName: "group-settings" */ '@/views/settings/management/roles/index/TheRoleIndexPage'
  )
const TheRoleForm = () =>
  import(
    /* webpackChunkName: "group-settings" */ '@/views/settings/management/roles/form/TheRoleForm'
  )

export const settingsRoleRoutes = [
  {
    path: 'roles-and-permissions',
    children: [
      {
        path: '',
        name: 'settings_list_roles_route',
        component: TheRoleIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_item_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_edit_role_route_main',
        props: true,
        component: TheRoleForm,
        children: [
          {
            path: '',
            components: TheRoleForm,
            name: 'settings_edit_role_route'
          }
        ],
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_automation_title', active: false },
            { title: 'content_automation_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_role_route',
        component: TheRoleForm,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_new_title', active: true }
          ]
        }
      }
    ]
  }
]
