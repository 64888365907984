import moment from 'moment/moment'

export default {
  filterList(objectList, filters, columnList) {
    let or_of_and = []
    objectList = objectList.filter((model) => {
      let flag = []

      filters.forEach((filter) => {
        or_of_and.push(filter.whereType)
        console.log(columnList)
        if ('column' in filter) {
          let modelAttributeValue = this.getModelValueForAttribute(
            columnList,
            filter,
            model
          )

          let filterValue = this.extractValueIfFilterValueIsObject(filter)

          this.compareFilterValueAndModelValue(
            filter,
            flag,
            modelAttributeValue,
            filterValue
          )
        }
      })

      let all_conditions_match = flag.filter((i) => !i).length === 0
      let any_conditions_match = flag.filter((i) => i).length !== 0

      let all_where = or_of_and.filter((el) => el === 'orWhere').length === 0

      if (
        (all_where && all_conditions_match) ||
        (!all_where && any_conditions_match)
      ) {
        return model
      }
    })
    return objectList
  },

  compareFilterValueAndModelValue: function (
    filter,
    flag,
    modelAttributeValue,
    filterValue
  ) {
    const type = ['due_date', 'emission_date'].includes(filter.column)
      ? 'date'
      : 'int'

    switch (filter.condition) {
      case '=':
        flag.push(this.areEqual(modelAttributeValue, filterValue, type))
        break
      case '!=':
        flag.push(!this.areEqual(modelAttributeValue, filterValue, type))
        break
      case 'in':
        flag.push(filterValue.includes(modelAttributeValue))
        break
      case 'not_in':
        flag.push(!filterValue.includes(modelAttributeValue))
        break
    }
  },
  extractValueIfFilterValueIsObject: function (filter) {
    let filterValue = filter.values

    if (['deceased_id', 'person_id', 'dossier_id'].includes(filter.column)) {
      if (['=', '!='].includes(filter.condition)) {
        filterValue = filterValue.id
      } else if (filterValue.length) {
        filterValue = filterValue.map((val) => val.id)
      } else {
        filterValue = []
      }
    }
    return filterValue
  },
  getModelValueForAttribute: function (columnList, filter, model) {
    let column = columnList.find((column) => column.id === filter.column)
    return column.attributes.split('.').reduce((o, i) => o?.[i], model)
  },
  areEqual(modelAttributeValue, filterValue, type) {
    switch (type) {
      case 'date':
        let attribute_to_date = moment(modelAttributeValue)
          .set('hour', 0)
          .set('minute', 1)
          .format('YYYY-MM-DD')
        let filter_to_date = moment(filterValue, 'DD/MM/YYYY')
          .set('hour', 0)
          .set('minute', 1)
          .format('YYYY-MM-DD')
        return attribute_to_date === filter_to_date
      default:
        return modelAttributeValue === filterValue
    }
  },
  removeAccents(input) {
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  },
  operators: {
    '=': (a, b) => {
      if (Array.isArray(a)) {
        return Array.isArray(b)
          ? JSON.stringify(a) === JSON.stringify(b)
          : a.includes(b)
      }
      return Array.isArray(b) ? b.includes(a) : a == b
    },
    '!=': (a, b) => {
      if (Array.isArray(a)) {
        return Array.isArray(b)
          ? JSON.stringify(a) !== JSON.stringify(b)
          : !a.includes(b)
      }
      return Array.isArray(b) ? !b.includes(a) : a != b
    },
    in: (a, arr) => {
      if (Array.isArray(a)) {
        return a.some((item) => arr.includes(item))
      }
      return arr.includes(a)
    },
    'not in': (a, arr) => {
      if (Array.isArray(a)) {
        return a.every((item) => !arr.includes(item))
      }
      return !arr.includes(a)
    }
  }
}
