import apiClient from '@u/apiClient'

import CheckActions from './check/actions'
import InvoiceItemAction from './invoiceItem/actions'
import PaymentActions from './payment/actions'
import ThirdPartyCostPaymentActions from './thirdPartyCostPayment/actions'
import PersonActions from './person/actions'
import SupplierOrderAction from './supplierOrder/action'
import ExportAction from './export/actions'
import InvoiceSendRecordAction from './invoiceSendRecord/actions'
import BankAction from './bank/actions'

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  ...CheckActions,
  ...InvoiceItemAction,
  ...PaymentActions,
  ...PersonActions,
  ...SupplierOrderAction,
  ...ExportAction,
  ...BankAction,
  ...ThirdPartyCostPaymentActions,
  ...InvoiceSendRecordAction,

  // region fetch
  async fetchInvoiceIndex({ commit }, doc_type) {
    await apiClient
      .get(`invoicing?doc_type=${doc_type}`)
      .then(async (response) => {
        await commit('setInvoiceIndex', response.data.invoices)
        await commit('setInvoiceMetadata', response.data.metadata)
      })
      .catch((reason) => {
        if (reason.response.status === 422) {
          commit('setErrors', reason.response.data.errors)
        }
      })
  },

  async fetchAllInvoices({ commit }) {
    await apiClient
      .get(`invoicing`)
      .then(async (response) => {
        await commit('setInvoiceIndex', response.data.invoices)
        await commit('setInvoiceMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        await console.error('fetchAllInvoices', reason)
      })
  },

  async fetchUnpaidInvoiceIndex({ commit }) {
    await apiClient
      .get('unpaid/invoicing')
      .then(async (response) => {
        await commit('setInvoiceIndex', response.data.invoices)
        await commit('setInvoiceMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        await commit('setErrors', reason.response.data.errors)
      })
  },

  async fetchPaidInvoiceIndex({ commit }) {
    await apiClient
      .get('paid/invoicing')
      .then(async (response) => {
        await commit('setInvoiceIndex', response.data.invoices)
        await commit('setInvoiceMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        await commit('setErrors', reason.response.data.errors)
      })
  },
  fetchInvoiceID: ({ commit }, payload) => {
    commit('auth/setErrors', {}, { root: true })
    return new Promise((resolve, reject) => {
      return apiClient
        .get(
          `invoicing/${payload.id}${
            payload.isSupplierOrder ? '?withSupplierOrderProperties=' + 1 : ''
          }`
        )
        .then((response) => {
          if (payload.supplier_order_index !== null) {
            commit('setInvoiceAtIndex', {
              index: payload.supplier_order_index,
              invoice: response.data.invoice
            })
          } else {
            commit('setInvoice', response.data.invoice)
            commit('setInvoiceMetadata', response.data.metadata)
          }
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchNewInvoice: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get('model/new', { params: { ...payload.params, model: 'invoice' } })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async fetchInvoiceActivitiesByInvoiceID({ commit }, payload) {
    await apiClient
      .get(`invoice/fetch-activities/${payload.invoice_id}`)
      .then(async (response) => {
        await commit('setMetadataActivities', response.data.metadata)
        await commit('setInvoiceActivity', response.data.activities)
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  },

  // endregion

  // region store
  async storeNewInvoice({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post('invoicing', payload.invoice)
        .then(async (response) => {
          resolve(response.data)
          if (response.status === 201) {
            await payload.successCallback(response?.data?.invoice?.id)
          }
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updateInvoice: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`invoicing/${payload.id}`, {
          invoice: payload.invoice,
          company: payload.company,
          funeralHome: payload.funeralHome,
          linkDossier: payload.linkDossier
        })

        .then((response) => {
          commit('setInvoice', response.data.invoice)
          commit('setInvoiceMetadata', response.data.metadata)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region delete
  // todo: supprimer cette action.
  async deleteInvoiceById({ commit }, payload) {
    await apiClient
      .delete(
        `invoicing/${payload.invoice_id}?doc_type=${
          payload.doc_type ?? null
        }&invoiceable=${payload.invoiceable_type ?? null}${
          payload.dossier_id ? '&dossier_id=' + payload.dossier_id : ''
        }`
      )
      .then(async (response) => {
        processSuccessCallback(payload)
        commit('setInvoiceIndex', response.data.invoices)
      })
      .catch(async (reason) => {
        console.error('deleteInvoiceById reason', reason)
      })
  },
  // todo: supprimer cette action.
  async undoDeleteInvoiceById({ commit }, payload) {
    await apiClient
      .get(
        `invoicing/undo-delete/${payload.invoice_id}?doc_type=${
          payload.doc_type ?? null
        }&invoiceable=${payload.invoiceable_type ?? null}${
          payload.dossier_id ? '&dossier_id=' + payload.dossier_id : ''
        }`
      )
      .then(async (response) => {
        processSuccessCallback(payload)
        commit('setInvoiceIndex', response.data.invoices)
      })
      .catch(async (reason) => {
        console.error('deleteInvoiceById reason', reason)
      })
  },
  // endregion

  // region change status invoice
  async changeInvoiceStatus({ commit }, payload) {
    await apiClient
      .put(
        `invoicing/${payload.invoice_id}/status${
          payload.supplier_id ? '?supplier_id=' + payload.supplier_id : ''
        }`,
        payload.form_data
      )
      .then(async (response) => {
        if ((await response.status) === 200) {
          commit('auth/setErrors', {}, { root: true })
          if (payload.supplier_id) {
            commit('setInvoiceIndex', response.data.invoices)
          } else {
            commit('setInvoice', response.data.invoice)
            commit('setInvoiceMetadata', response.data.metadata)
          }
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('fetchNewPayment reason', reason)
      })
  },
  // endregion

  // region change rôle invoice
  async changeInvoiceRole({ commit }, payload) {
    await apiClient
      .put(`invoicing/${payload.invoice_id}/role`, payload.form_data)
      .then(async (response) => {
        if ((await response.status) === 200) {
          commit('auth/setErrors', {}, { root: true })
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('fetchNewPayment reason', reason)
      })
  },
  // endregion

  // region clone invoice
  cloneInvoice: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`invoicing/${payload.invoice_id}/clone`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion
  // region clone invoice
  cloneInvoiceChangeCompanyOrAndFuneralHome: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      console.log(payload)
      apiClient
        .put(`invoicing/${payload.invoice_id}/clone`, {
          company: payload.company,
          funeralHome: payload.funeralHome,
          linkDossier: payload.linkDossier,
          newType: payload.newType
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion
  // region convert invoice
  convertInvoice: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`invoicing/${payload.invoice_id}/clone`, {
          new_type: payload.doc_type
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region send invoice
  async sendInvoice({ commit }, payload) {
    apiClient
      .post('send-invoice', payload.info)
      .then(async (response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error(reason)
      })
  },
  // endregion

  async fetchSendInvoiceMessageTextConverted({ commit }, payload) {
    await apiClient
      .post('convertMessageText', payload)
      .then((response) => {
        if (response.status === 200) {
          commit('setSendInvoiceMessageText', response.data.text)
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('fetchMessageTextConverted', reason)
      })
  },

  fetchInvoiceableList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`invoicing/${payload.invoiceId}/invoiceable`, {
          params: {
            filter: payload.filter
          }
        })
        .then((response) => {
          resolve(response.data.invoiceables)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  searchForAnInvoiceable: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`invoicing/search-invoiceable`, {
          params: payload.searchParams
        })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  fetchInvoiceEstablishMetadata: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`invoicing/${payload.invoice_id}/establish`, {
          params: { doc_role: payload.doc_role }
        })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  setInvoiceEstablish: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`invoicing/${payload.invoice_id}/establish`, {
          ...payload,
          _method: 'PUT'
        })
        .then(async (response) => {console.log(response.data.invoice)
          commit('setInvoice', response.data.invoice)
          commit('setInvoiceMetadata', response.data.metadata)
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  fetchHeaderForInvoice: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`invoicing/${payload.invoice_id}/headers`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  fetchInvoiceForMerging: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`invoicing/${payload.invoice_id}/merge-into`, {
          params: {
            doc_type: payload.doc_type
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  applyInvoiceMerging: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`invoicing/${payload.invoice_id}/apply-merge`, {
          into_invoice_id: payload.into_invoice_id
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  deleteInvoicing: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`invoicing/${payload.invoice_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  undoDeleteInvoicing: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`invoicing/${payload.invoice_id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  sendToAccounting: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`invoicing/${payload.invoice_id}/send-to-accounting`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
