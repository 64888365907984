export default {
  getPaymentModeList(state) {
    return state.paymentModeList
  },
  getPaymentMode(state) {
    return state.paymentMode
  },
  getPaymentModeMetadata(state) {
    return state.metadata
  },
  getPaymentModeActivity(state) {
    return state.PaymentModeActivity
  },
  getMetadataActivities(state) {
    return state.metadataActivities
  },
  getEditionMode(state) {
    return state.editionMode
  },
}
