<template>
  <div
    class="flex h-64 w-72 flex-col gap-2 rounded p-3 shadow-lg backdrop-blur-lg"
  >
    <div class="text-lg font-bold leading-none">{{ nameComputed }}</div>
    <div
      v-if="place"
      class="flex flex-col divide-y rounded-md bg-white font-medium"
    >
      <div v-if="!loading && place.address" class="p-2 capitalize">
        <h4 class="leading-tight text-gray-500">
          {{ $t('attributes.address') }}
        </h4>
        <div class="leading-tight">
          {{ place.address.street }}<br />{{ place.address.zip }}
          {{ place.address.city }}
        </div>
      </div>
      <div v-if="!loading && place.phone_field" class="p-2 capitalize">
        <h4 class="leading-tight text-gray-500">
          {{ $t('attributes.phone') }}
        </h4>
        <div class="leading-tight">
          {{ place.phone_field }}
        </div>
      </div>
      <div v-if="!loading && place.fax_field" class="p-2 capitalize">
        <h4 class="leading-tight text-gray-500">{{ $t('attributes.fax') }}</h4>
        <div class="leading-tight">
          {{ place.fax_field }}
        </div>
      </div>
      <div v-if="!loading && place.email" class="p-2 capitalize">
        <h4 class="leading-tight text-gray-500">
          {{ $t('attributes.email') }}
        </h4>
        <div class="leading-tight">
          {{ place.email }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MapPopupMarker',
  props: {
    placeId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    popupOpened: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      place: null,
      loading: false
    }
  },
  mounted() {
    this.getMarkerInfo()
  },
  computed: {
    nameComputed() {
      return this.place?.name[this.$i18n.locale] ?? this.name
    }
  },
  methods: {
    ...mapActions({
      processGetMarkerInfoAction: 'wiki/getMarkerInfo'
    }),
    getMarkerInfo() {
      this.loading = true
      this.processGetMarkerInfoAction({ place_id: this.placeId })
        .then((response) => {
          this.place = response.place
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
