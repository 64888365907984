import apiClient from '@u/apiClient'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch
  fetchAllThirdPartyCost({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`third_party_cost`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchNewThirdPartyPayment: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(`model/new?model=thirdPartyCostPayment`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  fetchPaymentById: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`invoicing/${payload.invoice_id}/payment/${payload.payment_id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // region store
  storeNewThirdPartyCostPayment: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise(async (resolve, reject) => {
      let url = `third-party-cost-payment`;
      apiClient
        .post(url, payload)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          }
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updateThirdPartyCostPaymentById: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      let url = `third-party-cost-payment/${payload.id}`;
      apiClient
        .put(url, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region delete
  deletePayment: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`invoicing/${payload.invoice_id}/payment/${payload.payment_id}`)
        .then((response) => {
          commit('setInvoice', response.data.invoice)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async undoDeletePayment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(
          `invoicing/${payload.invoice_id}/payment/${payload.payment_id}/undo-delete`
        )
        .then((response) => {
          commit('setInvoice', response.data.invoice)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
