<template>
  <div>
    <div class="flex flex-row items-center">
      <div class="flex flex-1 flex-row items-center gap-2">
        <h4 class="text-lg font-bold">{{ location.name }}</h4>
        <span v-if="itemLocationSetting">
          <span
            :data-tint="
              itemStocks?.length &&
              itemLocationSetting.stock_limit &&
              itemStocks.length < itemLocationSetting.stock_limit
                ? 'imperial-red'
                : 'america-green'
            "
            class="rounded bg-primary px-1.5 text-xs text-contrast-primary"
          >
            {{ itemStocks.length ?? '##' }} /
            {{ itemLocationSetting.stock_limit }}
          </span>
        </span>
      </div>
      <div>
        <button v-if="itemStocks?.length" @click="printLabelModalOpened = true">
          <FontAwesomeIcon :icon="'print'" />
        </button>
      </div>
    </div>
    <div
      class="relative min-h-[3rem] rounded border p-2"
      :class="[
        dragAndDropEnabled ? 'border-2 border-dashed' : '',
        dragAndDropEnabled && droppingAllowed
          ? 'border-green-500'
          : 'border-slate-300'
      ]"
      @dragover="handleDragOver"
      @dragleave="handleDragLeave"
      @drop.prevent="handleDrop"
    >
      <div class="z-10 grid grid-cols-4 gap-2">
        <template v-if="itemStocks.length">
          <ItemStockComponent
            v-for="item_stock in itemStocks"
            :key="item_stock"
            :item-stock="item_stock"
            :drag-and-drop-enabled="dragAndDropEnabled"
          />
        </template>
        <button
          v-if="!dragAndDropEnabled"
          class="group flex items-center justify-center rounded border border-slate-300 p-2 text-slate-900 hover:border-solid hover:border-primary-500 hover:bg-white hover:text-primary-500"
        >
          <FontAwesomeIcon :icon="['fal', 'plus']" size="lg" />
        </button>
      </div>
    </div>
  </div>
  <PrintLabelModal
    v-if="itemStocks[0]"
    v-model:opened="printLabelModalOpened"
    :item-id="itemStocks[0].item_id"
    :extra-params="{ location_id: location.id }"
  />
</template>

<script>
import ItemStockComponent from '@/views/stock/item/ItemStockComponent.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters } from 'vuex'
import PrintLabelModal from '@/views/stock/item/PrintLabelModal.vue'

export default {
  name: 'LocationStockComponent',
  components: { PrintLabelModal, FontAwesomeIcon, ItemStockComponent },
  props: {
    location: {
      type: Object,
      required: true
    },
    itemStocks: {
      type: Array,
      required: true
    },
    itemLocationSetting: {
      type: Object,
      required: true
    },
    dragAndDropEnabled: {
      type: Boolean,
      required: true
    }
  },
  emits: ['move-item-stock'],
  data() {
    return {
      droppingAllowed: false,
      printLabelModalOpened: false
    }
  },
  computed: {
    ...mapGetters({
      dataTransferObject: 'stock/getDataTransferObject'
    })
  },
  watch: {
    dragAndDropEnabled(newValue, oldValue) {
      if (!newValue) {
        this.droppingAllowed = false
      }
    }
  },
  methods: {
    handleDrop(event) {
      event.preventDefault()
      this.$emit('move-item-stock', this.dataTransferObject.model)
    },
    handleDragOver(event) {
      if (!this.dragAndDropEnabled || !this.dataTransferObject) {
        return
      }
      event.preventDefault()
      if (this.dataTransferObject.location_id !== this.location.id) {
        this.droppingAllowed = true
      } else {
        this.droppingAllowed = false
      }
    },
    handleDragLeave() {
      this.droppingAllowed = false
    }
  }
}
</script>
