<template>
  <tr :class="trClass">
    <td>
      <BaseButton
        :icon="buttonIcon"
        :title="buttonText"
        @click="showActivity = !showActivity"
      />
    </td>
    <td>{{ c_causer_name }}</td>
    <td class="capitalize-first">
      {{ $t('attributes.' + activity?.description) }}
    </td>
    <td>{{ c_activity_time }}</td>
  </tr>

  <tr v-if="showActivity" :class="trClass">
    <td colspan="4" style="padding: 0">
      <LogTable :model="activity.properties" />
    </td>
  </tr>
</template>

<script>
import LogTable from './LogTable.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
export default {
  name: 'ActivityTableRow',
  components: { BaseButton, LogTable },
  props: {
    activity: { type: Object, required: true },
    additionalTitleInformation: {
      type: Boolean,
      required: false,
      default: false
    },
    indexValue: {}
  },
  data() {
    return {
      showActivity: false
    }
  },
  computed: {
    ...mapGetters({
      users: 'metadata/getUsersMetadata'
    }),
    trClass() {
      return this.indexValue % 2 === 0 ? 'bg-gray-200 dark:bg-slate-800' : ''
    },
    buttonText() {
      return this.showActivity
        ? this.$t('button.reduce')
        : this.$t('button.show')
    },
    buttonIcon() {
      return this.showActivity ? 'minus' : 'plus'
    },
    c_activity_time() {
      return moment(this.activity.created_at).format('DD/MM/YYYY HH:mm')
    },
    c_causer_name() {
      return this.activity.causer_id
        ? this.users?.find((user) => user.id === this.activity.causer_id)
            ?.full_name
        : this.$t('global.system')
    }
  }
}
</script>
