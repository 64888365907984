<template>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
    <SelectComponent
      v-if="metadata?.causers"
      v-model="filter.causer"
      :values="metadata?.causers"
      :label="$t('attributes.user')"
      name="causers"
      :alphabetical-order="true"
    />
    <SelectComponent
      v-if="metadata?.events"
      v-model="filter.description"
      :values="metadata?.events"
      :label="$t('attributes.event')"
      name="events"
      :alphabetical-order="true"
    />
  </div>
</template>
<script>
import BaseShowEditSelectSimple from '@c/addf-package/components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseShowEditSelectSimple.vue'
import SelectSimpleComponent from '@c/BaseFormComponent/SelectSimpleComponent.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'

export default {
  name: 'ActivityFilterHeader',
  components: {
    SelectComponent,
    SelectSimpleComponent,
    BaseShowEditSelectSimple
  },
  props: {
    filter: {},
    metadata: {}
  }
}
</script>
