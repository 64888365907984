<template>
  <div class="flex h-full flex-col gap-2 overflow-auto">
    <button
      v-for="favoriteCity in favoriteCities"
      :key="favoriteCity.id"
      class="rounded border p-2 text-left"
      @click="
        $emit('click:favorite-city', {
          latitude: favoriteCity.latitude,
          longitude: favoriteCity.longitude
        })
      "
    >
      <h3 class="font-semibold">{{ favoriteCity.name[$i18n.locale] }}</h3>
      <h4># {{ favoriteCity.zip }}</h4>
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import JsonPretty from '@c/Utility/JsonPretty.vue'

export default {
  name: 'MapSideFavorite',
  components: { JsonPretty },
  emits: ['click:favorite-city'],
  computed: {
    ...mapGetters({
      favoriteCities: 'wiki/getFavoriteCities',
      initialMetadata: 'metadata/getInitialMetadata'
    })
  },
  beforeMount() {
    if (!this.favoriteCities?.length) {
      this.processFetchFavoriteCitiesAction()
    }
  },
  methods: {
    ...mapActions({
      processFetchFavoriteCitiesAction: 'wiki/fetchFavoriteCities'
    })
  }
}
</script>
