<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <h4 class="text-base font-bold">Global</h4>
      <div
        v-if="localSettings?.global"
        class="grid grid-cols-1 gap-2 md:grid-cols-6"
      >
        <div>
          <WalkmanButton
            label="type d'import"
            :options="import_modes"
            v-model="localSettings.global.import_mode"
            size="extra-small"
            :edition-mode="editionMode"
            track-by="id"
            attribute-label="name"
          />
        </div>
      </div>
      <h4 class="text-base font-bold">Settings</h4>
      <div
        v-if="localSettings?.settings"
        class="grid grid-cols-1 gap-2 md:grid-cols-6"
      >
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.corporation"
            label="corporation"
            name="corporation"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.listing"
            label="listing"
            name="listing"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.material_resources"
            label="material_resources"
            name="material_resources"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.users"
            label="utilisateur"
            name="users"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.dossier_numbering"
            label="dossier_numbering"
            name="dossier_numbering"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.invoicing_numbering"
            label="invoicing_numbering"
            name="invoicing_numbering"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.bank_accounts"
            label="bank_accounts"
            name="bank_accounts"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.tasks"
            label="tasks"
            name="tasks"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.dashboard"
            label="dashboard"
            name="dashboard"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.suppliers"
            label="suppliers"
            name="suppliers"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.invoicing_categories"
            label="invoicing_categories"
            name="invoicing_categories"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.item_categories"
            label="item_categories"
            name="item_categories"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.headers"
            label="headers"
            name="headers"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.items"
            label="items"
            name="items"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.automation_invoicing"
            label="automation_invoicing"
            name="automation_invoicing"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.contacts"
            label="automation_invoicing"
            name="automation_invoicing"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <h4 class="text-base font-bold">Defunt</h4>
      <div
        v-if="localSettings?.deceased"
        class="grid grid-cols-1 gap-2 md:grid-cols-6"
      >
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.deceased.deceased"
            label="défunt"
            name="corporation"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <h4 class="text-base font-bold">Facturation</h4>
      <div
        v-if="localSettings?.invoicing"
        class="grid grid-cols-1 gap-2 md:grid-cols-6"
      >
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.invoicing.invoice"
            label="invoice"
            name="invoice"
            size="extra-small"
            :edition-mode="editionMode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBooleanRadioGroup from '@c/addf-package/components/BaseRadioGroup/BaseBooleanRadioGroup.vue'
import WalkmanButton from '@c/WalkmanButton.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FuneralManagerV2ImportTaskSettings',
  components: { WalkmanButton, BaseBooleanRadioGroup },
  props: {
    settings: {
      type: Object,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:settings'],
  computed: {
    ...mapGetters({
      import_modes: 'metadata/getImportModes'
    }),
    localSettings: {
      get() {
        return this.settings
      },
      set(value) {
        this.$emit('update:settings', value)
      }
    }
  },
  mounted() {
    if (this.editionMode) {
      this.localSettings = {
        global: {
          import_mode: 'insert_only'
        },
        settings: {
          corporation: 0,
          listing: 0,
          material_resources: 0,
          users: 0,
          dossier_numbering: 0,
          invoicing_numbering: 0,
          bank_accounts: 0,
          tasks: 0,
          dashboard: 0,
          suppliers: 0,
          invoicing_categories: 0,
          item_categories: 0,
          headers: 0,
          items: 0,
          automation_invoicing: 0,
          contacts: 0
        },
        deceased: {
          deceased: 1
        },
        invoicing: {
          invoice: 0
        }
      }
    }
  }
}
</script>
