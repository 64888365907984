import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      paymentModeList: [],
      paymentMode: {},
      metadata: [],
      metadataActivities: [],
      PaymentModeActivity: {},
      editionMode: false
    }
  },
  getters,
  actions,
  mutations
}
