<template>
  <div class="box flex h-full flex-row overflow-hidden">
    <div class="relative flex-1 transition-all">
      <MapboxMap
        :access-token="mapBoxToken"
        style="height: 100%"
        map-style="mapbox://styles/francoisducobu/cm54z9jr300fa01r1eo5e69mv"
        :zoom="16"
        @mb-created="(mapInstance) => (map = mapInstance)"
        @mb-moveend="moveendHandle"
      >
        <MapboxMarker
          v-for="marker in funeralHomesMarkers"
          :key="marker.id"
          :lng-lat="[marker.longitude, marker.latitude]"
        >
          <template #default>
            <GenericMarker size="medium" />
          </template>
        </MapboxMarker>
        <MapMarker
          v-for="marker in markers"
          :key="marker.id"
          :marker="marker"
          @set-center="flyTo"
        />
        <MapboxNavigationControl position="bottom-right" />
      </MapboxMap>
      <div class="absolute left-2 top-2 flex w-96 flex-col gap-4">
        <SearchMarker
          :ajax-extra-params="{
            longitude: longitude,
            latitude: latitude
          }"
          @selected-marker="flyTo"
        />
        <MapSidePlace
          v-if="placeId"
          :place-id="placeId"
          @set-center="flyTo"
          @display-tiles="displayTiles"
        />
      </div>
      <div class="absolute right-2 top-2 rounded-md bg-white p-2 shadow-md">
        <button
          class="rounded-md bg-secondary p-1"
          @click="sideBarOpen = !sideBarOpen"
        >
          <FontAwesomeIcon :icon="['fal', 'sidebar']" fixed-width />
        </button>
      </div>
    </div>
    <div
      class="transition-all"
      :class="{
        'w-72': sideBarOpen,
        'w-0': !sideBarOpen
      }"
    >
      <MapSideBar @change-center="flyTo" />
    </div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import {
  MapboxMap,
  MapboxMarker,
  MapboxNavigationControl
} from '@studiometa/vue-mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import MapSideBar from '@/views/wiki/communes/map/components/MapSideBar.vue'
import MapPopupMarker from '@/views/wiki/communes/map/components/MapPopupMarker.vue'
import { mapActions, mapGetters } from 'vuex'
import MapMarker from '@/views/wiki/communes/map/components/MapMarker.vue'
import MapSidePlace from '@/views/wiki/communes/map/components/MapSidePlace.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import SelectAjax from '@c/SelectAjaxComponent/component/SelectAjax.vue'
import SearchMarker from '@/views/wiki/communes/map/components/SearchMarker.vue'
import { debounce } from '@u/debounce'
import JsonPretty from '@c/Utility/JsonPretty.vue'
import CemeteryMarker from '@/views/wiki/communes/map/components/marker/CemeteryMarker.vue'
import ChurchMarker from '@/views/wiki/communes/map/components/marker/ChurchMarker.vue'
import HospitalMarker from '@/views/wiki/communes/map/components/marker/HospitalMarker.vue'
import MunicipalityMarker from '@/views/wiki/communes/map/components/marker/MunicipalityMarker.vue'
import CrematoriumMarker from '@/views/wiki/communes/map/components/marker/CrematoriumMarker.vue'
import GenericMarker from '@/views/wiki/communes/map/components/marker/GenericMarker.vue'
import NursingHomeMarker from '@/views/wiki/communes/map/components/marker/NursingHomeMarker.vue'

export default {
  name: 'TheWikiMapPage',
  components: {
    NursingHomeMarker,
    GenericMarker,
    CrematoriumMarker,
    MunicipalityMarker,
    HospitalMarker,
    ChurchMarker,
    CemeteryMarker,
    JsonPretty,
    SearchMarker,
    SelectAjax,
    SelectComponent,
    MapSidePlace,
    MapMarker,
    MapPopupMarker,
    MapSideBar,
    FontAwesomeIcon,
    MapboxMap,
    MapboxNavigationControl,
    MapboxMarker
  },
  data() {
    return {
      map: null,
      mapStyle: 'mapbox://styles/francoisducobu/cm54z9jr300fa01r1eo5e69mv',
      latitude: null,
      longitude: null,
      bounds: null,
      locationUnavailable: false,
      zoom: 13,
      sideBarOpen: false,
      markers: [],
      currentPlace: null
    }
  },
  props: {
    type: {
      // recu du router
      type: String,
      required: true
    },
    placeId: {
      // recu du router
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      funeralHomes: 'metadata/getFuneralHomesMetadata'
    }),
    funeralHomesMarkers() {
      return this.funeralHomes.map((funeralHome) => {
        return {
          longitude: funeralHome.address.longitude,
          latitude: funeralHome.address.latitude
        }
      })
    },
    center() {
      if (this.latitude !== null && this.longitude !== null)
        return {
          lat: parseFloat(this.latitude),
          lng: parseFloat(this.longitude)
        }
      return undefined
    },
    mapBoxToken() {
      return import.meta.env.VITE_MAPBOX_API_TOKEN
    }
  },
  watch: {
    placeId(newValue, oldValue) {
      if (!newValue) {
        this.map.flyTo({
          zoom: 14
        })
        this.removeTiles()
      }
    }
  },
  created() {
    this.debouncedMarkerInBounds = debounce((bounds) => {
      this.getMarkerInBounds(bounds)
    }, 1000)
  },
  mounted() {
    this.setMapBoundsBasedOnMarkers()
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       this.locationUnavailable = false
    //       this.longitude = position.coords.longitude
    //       this.latitude = position.coords.latitude
    //       this.map.setCenter([
    //         position.coords.longitude,
    //         position.coords.latitude
    //       ])
    //     },
    //     (_error) => {
    //       this.locationUnavailable = true
    //       console.warn(_error)
    //     },
    //     { timeout: 10000 }
    //   )
    // } else {
    //   console.warn('Geolocation is not supported')
    // }
  },
  methods: {
    ...mapActions({
      processGetMarkersArroundAction: 'wiki/getMarkersArround'
    }),
    flyTo(payload) {
      this.longitude = payload.longitude
      this.latitude = payload.latitude
      this.map.flyTo({
        center: [payload.longitude, payload.latitude],
        zoom: payload.zoom ?? 13
      })
    },
    getMarkerArround() {
      const { lng, lat } = this.map.getCenter()
      this.processGetMarkersArroundAction({
        longitude: lng,
        latitude: lat
      }).then((response) => {
        this.markers = response.places
      })
    },
    setMapBoundsBasedOnMarkers(markers) {
      markers = markers || this.funeralHomesMarkers
      const bounds = new mapboxgl.LngLatBounds()
      markers.forEach((marker) => {
        bounds.extend([marker.longitude, marker.latitude])
      })
      this.map.fitBounds(bounds, {
        padding: { top: 50, bottom: 50, left: 50, right: 50 },
        animate: false
      })
      this.getMarkerInBounds()
    },
    getMarkerInBounds(bounds) {
      bounds = bounds || this.map.getBounds()
      let zoom = this.map.getZoom()
      if (zoom < 10 || !bounds || bounds.isEmpty()) {
        return
      }
      this.processGetMarkersArroundAction({
        bounds: {
          ne: bounds.getNorthEast(),
          nw: bounds.getNorthWest(),
          sw: bounds.getSouthWest(),
          se: bounds.getSouthEast()
        }
      }).then((response) => {
        this.markers = response.places
      })
    },
    displayTiles(tiles_key) {
      this.map.addSource('tileserver', {
        type: 'raster',
        tiles: [
          `https://maps.funeralmanager.rip/api/tiles/${tiles_key}/{z}/{x}/{y}`
        ],
        minzoom: 15,
        maxzoom: 22
      })
      this.map.addLayer({
        id: 'tileserver',
        type: 'raster',
        source: 'tileserver'
      })
    },
    removeTiles() {
      this.map.removeLayer('tileserver')
      this.map.removeSource('tileserver')
    },
    moveendHandle(event) {
      this.debouncedMarkerInBounds(event.target.getBounds())
    }
  }
}
</script>

<style>
.mapboxgl-popup-content {
  padding: 0;
  background: transparent;
}
</style>
