<template>
  <div v-if="payment_mode" class="min-w-0 flex-1">
    <TabContentFormComponent
      :edit-button-condition="editButtonCondition"
      :edition-mode="editionMode"
      :main-button-display-condition="mainButtonDisplayCondition"
      :main-button-function="SubmitForm"
      :main-button-icon="mainButtonIcon"
      :main-button-title="mainButtonTitle"
      :page-header="true"
      :page-title="pageTitle"
      :redirect-back-button-function="redirectBackButtonFunction"
      :switch-edit-mode-function="switchEditMode"
      :tabs="tabs"
    />
  </div>
</template>

<script>
import TabContentFormComponent from '@c/TabContentFormComponent'
import LoadingComponent from '@c/TheLoadingComponent'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ThePaymentModeFormsTabComponent',
  components: { LoadingComponent, TabContentFormComponent },
  props: {
    id: {
      // recu du routeur
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      payment_mode: 'paymentMode/getPaymentMode',
      editionMode: 'paymentMode/getEditionMode',
      auth: 'auth/getAuth'
    }),
    tabs() {
      return [
        {
          label: this.$t('global.informations'),
          icon: 'info-square',
          routeName: `settings_${this.routeType}_payment_mode_route`,
          gate: true
        },
        {
          label: this.$t('global.activities'),
          icon: 'file-alt',
          routeName: `settings_${this.routeType}_payment_mode_activity_route`,
          gate: this.$route.meta.type === 'edit'
        }
      ]
    },
    editButtonCondition() {
      let nonEditablePages = ['settings_edit_payment_mode_activity_route']
      return (
        this.routeType === 'edit' &&
        this.$can('manage_content') &&
        !nonEditablePages.includes(this.$route.name)
      )
    },
    pageTitle() {
      if (this.isInCreateMode) {
        return this.$t('button.add_new_m', {
          var: this.$t('global.payment_mode')
        })
      } else {
        return this.payment_mode.denomination?.[this.$i18n.locale]
      }
    },
    mainButtonTitle() {
      if (this.$route.meta.type === 'edit') {
        return this.$t('button.edit_new_m', {
          var: this.$t('global.payment_mode')
        })
      }
      if (this.$route.meta.type === 'create') {
        return this.$t('button.save_new_m', {
          var: this.$t('global.payment_mode')
        })
      }
      return ''
    },
    mainButtonIcon() {
      return 'paper-plane'
    },
    isInCreateMode() {
      return this.$route.meta.type === 'create'
    },
    isInEditMode() {
      return this.$route.meta.type === 'edit'
    },
    routeType() {
      return this.$route.meta.type
    },
    mainButtonDisplayCondition() {
      if (this.isInCreateMode) {
        return true
      }
      if (this.isInEditMode) {
        return this.editionMode
      }
      return false
    }
  },
  async beforeMount() {
    if (this.$route.meta.type === 'edit') {
      await this.processFetchPaymentModeByIdAction({
        id: this.$route.params.id
      })
    } else {
      await this.processFetchNewPaymentModeAction()
      this.processSetEditionModeMutation(true)
    }
  },
  methods: {
    ...mapMutations({
      processSetEditionModeMutation: 'paymentMode/setEditionMode'
    }),
    ...mapActions({
      processFetchPaymentModeByIdAction: 'paymentMode/fetchPaymentModeById',
      processFetchNewPaymentModeAction: 'paymentMode/fetchNewPaymentMode',
      processUpdatePaymentModeAction: 'paymentMode/updatePaymentMode',
      processStorePaymentModeAction: 'paymentMode/storePaymentMode'
    }),
    switchEditMode() {
      if (this.editionMode === true) {
        this.processFetchPaymentModeByIdAction({ id: this.$route.params.id })
      }
      this.processSetEditionModeMutation(!this.editionMode)
    },
    redirectBackButtonFunction() {
      this.$router.push({ name: 'settings_list_payment_mode_route' })
    },
    SubmitForm() {
      if (this.$route.meta.type === 'edit') {
        this.processUpdatePaymentModeAction({
          payment_mode: this.payment_mode,
          id: this.$route.params.id,
        }).then((response) => {
          this.$h.toastSuccess(
            this.$t('toasts.updated_m', { var: this.$t('global.payment_mode') })
          )
        })
      }
      if (this.$route.meta.type === 'create') {
        this.processStorePaymentModeAction({
          payment_mode: this.payment_mode
        }).then((response) => {
          this.$h.toastSuccess(
            this.$t('toasts.created_m', { var: this.$t('global.payment_mode') })
          )
        })
      }
    }
  }
}
</script>
