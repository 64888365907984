<template>
  <SelectAjax
    v-model:value-object="localItem"
    :url="'item-for-select'"
    attribute-label="name"
    :label="$t('global.item')"
    :disabled="disabled"
  />
</template>

<script>
import { defineComponent } from 'vue'
import SelectAjax from '@c/SelectAjaxComponent/component/SelectAjax.vue'

export default defineComponent({
  name: 'SelectItem',
  components: { SelectAjax },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:item', 'unselect', 'select'],
  computed: {
    localItem: {
      get() {
        return this.item
      },
      set(value) {
        this.$emit('update:item', value)
        if (value) {
          this.$emit('select', true)
        } else {
          this.$emit('unselect', true)
        }
      }
    }
  }
})
</script>
