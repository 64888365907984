<template>
  <div class="w-full">
    <div
      class="base-input relative rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
      :class="{
        'cursor-context-menu': disabled,
        disabled: disabled,
        'bg-gray-100': disabled
      }"
    >
      <BaseLabelTop
        :target="name"
        :label="label"
        :disabled="disabled"
        :required="required"
      />
      <input
        :id="name"
        v-model="localValue"
        :name="name"
        :type="type"
        :min="min"
        :max="max"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        class="base-input block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        :class="{
          [cInputClass]: !!cInputClass,
          disabled: disabled,
          'bg-gray-100': disabled
        }"
      />
    </div>
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{
        $t(error, {
          attribute: $t('attributes.' + name),
          max: max,
          min: min
        })
      }}
    </div>
  </div>
</template>

<script>
import BaseLabelTop from '@c/addf-package/components/BaseLabel/BaseLabelTop.vue'

export default {
  name: 'BaseInput',
  components: { BaseLabelTop },
  props: {
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: function (value) {
        const admittedTypes = [
          'text',
          'email',
          'url',
          'tel',
          'search',
          'password',
          'textarea',
          'number'
        ]
        return admittedTypes.indexOf(value) !== -1
      }
    },
    parseType: {
      type: String,
      required: false,
      default: 'text',
      validator: function (value) {
        const admittedTypes = ['text', 'int', 'float']
        return admittedTypes.indexOf(value) !== -1
      }
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    errors: {
      type: Object,
      required: false,
      default: null
    },
    min: {
      type: Number,
      required: false,
      default: null
    },
    max: {
      type: Number,
      required: false,
      default: null
    }
  },
  emits: ['input', 'update:model-value'],
  computed: {
    localValue: {
      set(value) {
        if (this.type === 'number') {
          switch (this.parseType) {
            case 'int':
              value = value !== '' && !isNaN(value) ? parseInt(value) : ''
              break
            case 'float':
              value = value !== '' && !isNaN(value) ? parseFloat(value) : ''
              break
            default:
              value =
                this.max && parseInt(this.max) < value
                  ? parseInt(this.max)
                  : value
          }
        }

        this.$emit('update:model-value', value)
      },

      get() {
        return this.modelValue
      }
    },
    cGroupClass() {
      return this.groupClass === '' ? '' : this.groupClass
    },
    cLabelClass() {
      return this.labelClass === '' ? '' : this.labelClass
    },
    cInputClass() {
      return this.inputClass === '' ? '' : this.inputClass
    }
  },
  watch: {
    modelValue(newValue) {
      this.$emit('input', {
        value: newValue,
        attribute: this.name
      })
    }
  },
  methods: {
    updateInput(event) {
      if (this.type === 'number') {
        let newValue
        switch (this.parseType) {
          case 'int':
            newValue =
              event.target.value !== '' && !isNaN(event.target.value)
                ? parseInt(event.target.value)
                : ''
            break
          case 'float':
            newValue =
              event.target.value !== '' && !isNaN(event.target.value)
                ? parseFloat(event.target.value)
                : ''
            break
          default:
            newValue =
              this.max && parseInt(this.max) < event.target.value
                ? parseInt(this.max)
                : event.target.value
        }
        this.$emit('update:model-value', newValue)
      } else {
        this.$emit('update:model-value', event.target.value)
      }
    }
  }
}
</script>

<style>
.base-input.disabled {
  cursor: context-menu;
}
</style>
