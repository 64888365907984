<template>
  <FontAwesomeLayers :class="sizeClass">
    <FontAwesomeIcon
      :icon="['fas', 'location-pin']"
      class="text-red-800"
      fixed-width
    />
    <FontAwesomeIcon
      :icon="['fas', 'fire-flame-simple']"
      transform="shrink-8 up-2"
      class="text-white"
      fixed-width
    />
  </FontAwesomeLayers>
</template>

<script>
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome'

export default {
  name: 'CrematoriumMarker',
  components: { FontAwesomeLayers, FontAwesomeIcon },
  props: {
    size: {
      type: String,
      default: 'small'
    }
  },
  computed: {
    sizeClass() {
      switch (this.size) {
        case 'extra-small':
          return ''
        case 'small':
          return 'fa-2x'
        case 'medium':
          return 'fa-3x'
        case 'large':
          return 'fa-4x'
        case 'extra-large':
          return 'fa-5x'
      }
    }
  }
}
</script>
