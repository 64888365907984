import { Partners } from '@/assets/enums'

import enaos from '../../images/partners/enaos.svg'
import yousign from '../../images/partners/yousign.svg'
import fragment from '../../images/partners/fragment.svg'
import funeup from '../../images/partners/funeup.svg'
import cremagest from '../../images/partners/cremagest.svg'
import sorenir from '../../images/partners/sorenir.svg'
import legacio from '../../images/partners/legacio.svg'
import kitty_for_relatives from '../../images/partners/kitty_for_relatives.svg'
import chift from '../../images/partners/chift.svg'
import square_up from '../../images/partners/squareup.svg'
import i_assure from '../../images/partners/iassure.svg'
import mesange_prevoyance from '../../images/partners/mesange_prevoyance.svg'
import cybille from '../../images/partners/cybille.svg'
import funebra from '../../images/partners/funebra.svg'

const PartnersLogo = Object.freeze({
  [Partners.ENAOS]: enaos,
  [Partners.FRAGMENT]: fragment,
  [Partners.FUNE_UP]: funeup,
  [Partners.YOUSIGN]: yousign,
  [Partners.CREMAGEST]: cremagest,
  [Partners.SORENIR]: sorenir,
  [Partners.LEGACIO]: legacio,
  [Partners.KITTY_FOR_RELATIVES]: kitty_for_relatives,
  [Partners.CHIFT]: chift,
  [Partners.SQUARE_UP]: square_up,
  [Partners.I_ASSURE]: i_assure,
  [Partners.MESANGE_PREVOYANCE]: mesange_prevoyance,
  [Partners.CYBILLE]: cybille,
  [Partners.FUNEBRA]: funebra
})

export default PartnersLogo
