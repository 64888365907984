import TheDossierSuperAdminManagementPage from '@/views/dossier/form/subpages/managenment/TheDossierSuperAdminManagementPage.vue'
const TheDossierOtherEventsPage = () =>
  import(
    '@/views/dossier/form/subpages/otherEvents/TheDossierOtherEventsPage.vue'
  )

const TheScreenPage = () =>
  import('@/views/dossier/form/subpages/screen/TheScreenPage.vue')

const TheDossierSitePage = () =>
  import('@/views/dossier/form/subpages/site/TheDossierSitePage.vue')

const TheItemManagementPage = () =>
  import(
    '@/views/dossier/form/subpages/itemManagement/TheItemManagementPage.vue'
  )

const TheDossierIndexPage = () =>
  import('@/views/dossier/index/TheDossierIndexPage')
const TheDossierFormEditPage = () =>
  import('@/views/dossier/form/TheDossierFormEditPage')
const TheDossierInformationPage = () =>
  import('@/views/dossier/form/subpages/informations/TheDossierInformationPage')
const TheDossierAttachmentsPage = () =>
  import(
    '@/views/dossier/form/subpages/attachments/TheDossierAttachmentsPage.vue'
  )
const TheDossierTasksPage = () =>
  import('@/views/dossier/form/subpages/taskDossier/TheTasksDossierPage')
const TheDossierActivitiesPage = () =>
  import('@/views/dossier/form/subpages/activities/TheDossierActivitiesPage')
const TheDossierInvoicingRouter = () =>
  import('@/views/dossier/form/subpages/invoicing/TheDossierInvoicingRouter')
const TheDossierInvoicingPage = () =>
  import('@/views/dossier/form/subpages/invoicing/TheDossierInvoicingPage')
const InvoicingInfo = () =>
  import('@/views/invoicing/show/invoicingTab/InvoicingInfo')
const InvoicingForm = () => import('@/views/invoicing/form/InvoicingForm')

const TheDossierContactPage = () =>
  import('@/views/dossier/form/subpages/contact/TheDossierContactPage')
const TheDossierFuneralPage = () =>
  import('@/views/dossier/form/subpages/funeral/TheDossierFuneralPage')
const TheDossierRemainsPage = () =>
  import('@/views/dossier/form/subpages/remains/TheDossierRemainsPage')
const TheDossierCoffinAndProductsPage = () =>
  import(
    '@/views/dossier/form/subpages/coffinAndProducts/TheDossierCoffinAndProductsPage'
  )
const TheDossierInsurancePage = () =>
  import('@/views/dossier/form/subpages/insurance/TheDossierInsurancePage')
const TheDossierParticularitiesPage = () =>
  import(
    '@/views/dossier/form/subpages/particularities/TheDossierParticularitiesPage'
  )
const TheDossierReceptionPage = () =>
  import(
    '@/views/dossier/form/subpages/mourningReception/TheDossierReceptionPage'
  )
const TheDossierCondolencePage = () =>
  import('@/views/dossier/form/subpages/condolence/TheDossierCondolencePage')
const TheDossierOtherProductsPage = () =>
  import(
    '@/views/dossier/form/subpages/otherProducts/TheDossierOtherProductsPage'
  )
const TheDossierWizard = () =>
  import('@/views/dossier/form/subpages/wizard/TheDossierWizard')
const TheDossierDocumentShowPage = () =>
  import('@/views/dossier/form/subpages/documents/TheDossierDocumentShowPage')
const TheNewDossierDocumentsPage = () =>
  import('@/views/dossier/form/subpages/documents/TheNewDossierDocumentsPage')
const TheDossierObituaryPage = () =>
  import('@/views/dossier/form/subpages/obituary/TheDossierObituaryPage')
const TheDossierSupplierOrderPage = () =>
  import(
    '@/views/dossier/form/subpages/supplierOrder/TheDossierSupplierOrderPage'
  )
const TheDossierSummaryPage = () =>
  import('@/views/dossier/form/subpages/summary/TheDossierSummaryPage.vue')
const TheDossierEnaosPage = () =>
  import('@/views/dossier/form/subpages/Partners/Enaos/TheDossierEnaosPage.vue')
const TheDossierFunebraPage = () =>
  import(
    '@/views/dossier/form/subpages/Partners/Funebra/TheDossierFunebraPage.vue'
  )
const TheDossierSorenirPage = () =>
  import(
    '@/views/dossier/form/subpages/Partners/Sorenir/TheDossierSorenirPage.vue'
  )
const TheDossierLegacioPage = () =>
  import(
    '@/views/dossier/form/subpages/Partners/Legacio/TheDossierLegacioPage.vue'
  )
const TheDossierKittyForRelativesPage = () =>
  import(
    '@/views/dossier/form/subpages/Partners/KittyForRelatives/TheDossierKittyForRelativesPage.vue'
  )
const TheDossierIAssurePage = () =>
  import(
    '@/views/dossier/form/subpages/Partners/IAssure/TheDossierIAssurePage.vue'
  )
const TheDossierMesangePrevoyancePage = () =>
  import(
    '@/views/dossier/form/subpages/Partners/MesangePrevoyance/TheDossierMesangePrevoyancePage.vue'
  )
const TheDossierHeavenPage = () =>
  import(
    '@/views/dossier/form/subpages/Partners/Heaven/TheDossierHeavenPage.vue'
  )
const TheDossierFragmentPage = () =>
  import(
    '@/views/dossier/form/subpages/Partners/Fragment/TheDossierFragmentPage.vue'
  )
const TheDossierCybillePage = () =>
  import(
    '@/views/dossier/form/subpages/Partners/Cybille/TheDossierCybillePage.vue'
  )
const TheDossierFuneUpPage = () =>
  import(
    '@/views/dossier/form/subpages/Partners/FuneUp/TheDossierFuneUpPage.vue'
  )
const TheDossierLightFormEditPage = () =>
  import('@/views/dossier/lightForm/TheDossierLightFormEditPage.vue')
const TheDossierLightInformation = () =>
  import('@/views/dossier/lightForm/subPages/TheDossierInformationLight.vue')
const TheDossierLightContactPage = () =>
  import('@/views/dossier/lightForm/subPages/TheDossierContactsLight.vue')
const TheDossierLightRemainsPage = () =>
  import('@/views/dossier/lightForm/subPages/TheDossierRemainLight.vue')
const TheDossierLightAttachmentsPage = () =>
  import('@/views/dossier/lightForm/subPages/TheDossierAttachmentsLight.vue')
const TheDossierParticularityLight = () =>
  import('@/views/dossier/lightForm/subPages/TheDossierParticularityLight.vue')
const TheDossierPicturePage = () =>
  import('@/views/dossier/form/subpages/picture/TheDossierPicturePage.vue')
const ThePreviewPicturePage = () =>
  import(
    '@/views/dossier/form/subpages/picture/subpages/ThePreviewPicturePage.vue'
  )
const TheGalleryPicturePage = () =>
  import(
    '@/views/dossier/form/subpages/picture/subpages/TheGalleryPicturePage.vue'
  )

const TheEditPicturePage = () =>
  import(
    '@/views/dossier/form/subpages/picture/subpages/TheEditPicturePage.vue'
  )

const TheEditGalleryPicturePage = () =>
  import(
    '@/views/dossier/form/subpages/picture/subpages/TheEditGalleryPicturePage.vue'
  )

const TheDossierExternalServicesPage = () =>
  import(
    '@/views/dossier/form/subpages/externalServices/TheDossierExternalServicesPage.vue'
  )

const TheAiPage = () =>
  import('@/views/dossier/form/subpages/picture/subpages/TheAiPage.vue')

export const dossierPages = [
  {
    path: 'dossier',
    children: [
      {
        path: '',
        name: 'dossier_death_route',
        component: TheDossierIndexPage,
        meta: {
          dossierType: 'DEC'
        }
      },
      {
        path: 'new',
        name: 'dossier_create_route',
        component: TheDossierWizard,
        meta: {
          type: 'create'
        }
      },
      {
        path: ':id',
        name: 'dossier_edit_route',
        props: true,
        component: TheDossierFormEditPage,
        children: [
          {
            path: '',
            name: 'dossier_edit_route_base',
            redirect: { name: 'dossier_form_edit_route' }
          },
          {
            path: 'summary',
            component: TheDossierSummaryPage,
            name: 'dossier_form_edit_route',
            props: true,
            meta: {
              display_type: 'view',
              pre_page: null,
              next_page: 'dossier_form_information_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'information',
            children: [
              {
                path: 'view',
                component: TheDossierInformationPage,
                name: 'dossier_form_information_route',
                meta: {
                  display_type: 'view',
                  switch_type_route: 'dossier_form_information_route_edit',
                  pre_page: 'dossier_form_edit_route',
                  next_page: 'dossier_form_contact_route',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'edit',
                component: TheDossierInformationPage,
                name: 'dossier_form_information_route_edit',
                meta: {
                  display_type: 'edit',
                  switch_type_route: 'dossier_form_information_route',
                  pre_page: 'dossier_form_edit_route_edit',
                  next_page: 'dossier_form_contact_route_edit',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              }
            ]
          },
          {
            path: 'contact',
            children: [
              {
                path: 'view',
                component: TheDossierContactPage,
                name: 'dossier_form_contact_route',
                meta: {
                  display_type: 'view',
                  switch_type_route: 'dossier_form_contact_route_edit',
                  pre_page: 'dossier_form_information_route',
                  next_page: 'dossier_form_remains_route',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'edit',
                component: TheDossierContactPage,
                name: 'dossier_form_contact_route_edit',
                meta: {
                  display_type: 'edit',
                  switch_type_route: 'dossier_form_contact_route',
                  pre_page: 'dossier_form_information_route_edit',
                  next_page: 'dossier_form_remains_route_edit',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              }
            ]
          },
          {
            path: 'remains',
            children: [
              {
                path: 'view',
                component: TheDossierRemainsPage,
                name: 'dossier_form_remains_route',
                meta: {
                  display_type: 'view',
                  switch_type_route: 'dossier_form_remains_route_edit',
                  pre_page: 'dossier_form_contact_route',
                  next_page: 'dossier_form_funeral_route',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'edit',
                component: TheDossierRemainsPage,
                name: 'dossier_form_remains_route_edit',
                meta: {
                  display_type: 'edit',
                  switch_type_route: 'dossier_form_remains_route',
                  pre_page: 'dossier_form_contact_route_edit',
                  next_page: 'dossier_form_funeral_route_edit',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              }
            ]
          },
          {
            path: 'funeral',
            children: [
              {
                path: 'view',
                component: TheDossierFuneralPage,
                name: 'dossier_form_funeral_route',
                meta: {
                  display_type: 'view',
                  switch_type_route: 'dossier_form_funeral_route_edit',
                  pre_page: 'dossier_form_remains_route',
                  next_page: 'dossier_form_coffin_and_product_route',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'edit',
                component: TheDossierFuneralPage,
                name: 'dossier_form_funeral_route_edit',
                meta: {
                  display_type: 'edit',
                  switch_type_route: 'dossier_form_funeral_route',
                  pre_page: 'dossier_form_remains_route_edit',
                  next_page: 'dossier_form_coffin_and_product_route_edit',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              }
            ]
          },
          {
            path: 'coffin-and-products',
            children: [
              {
                path: 'view',
                component: TheDossierCoffinAndProductsPage,
                name: 'dossier_form_coffin_and_product_route',
                meta: {
                  display_type: 'view',
                  switch_type_route:
                    'dossier_form_coffin_and_product_route_edit',
                  pre_page: 'dossier_form_funeral_route',
                  next_page: 'dossier_form_particularities_route',
                  // Keep for implementation of this page
                  // next_page: 'dossier_form_insurance_route',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'edit',
                component: TheDossierCoffinAndProductsPage,
                name: 'dossier_form_coffin_and_product_route_edit',
                meta: {
                  display_type: 'edit',
                  switch_type_route: 'dossier_form_coffin_and_product_route',
                  pre_page: 'dossier_form_funeral_route_edit',
                  next_page: 'dossier_form_particularities_route_edit',
                  // Keep for implementation of this page
                  // next_page: 'dossier_form_insurance_route_edit',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              }
            ]
          },
          {
            path: 'insurance',
            children: [
              {
                path: 'view',
                component: TheDossierInsurancePage,
                name: 'dossier_form_insurance_route',
                meta: {
                  display_type: 'view',
                  switch_type_route: 'dossier_form_insurance_route_edit',
                  pre_page: 'dossier_form_coffin_and_product_route',
                  next_page: 'dossier_form_particularities_route',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'edit',
                component: TheDossierInsurancePage,
                name: 'dossier_form_insurance_route_edit',
                meta: {
                  display_type: 'edit',
                  switch_type_route: 'dossier_form_insurance_route',
                  pre_page: 'dossier_form_coffin_and_product_route_edit',
                  next_page: 'dossier_form_particularities_route_edit',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              }
            ]
          },
          {
            path: 'particularities',
            children: [
              {
                path: 'view',
                component: TheDossierParticularitiesPage,
                name: 'dossier_form_particularities_route',
                meta: {
                  display_type: 'view',
                  switch_type_route: 'dossier_form_particularities_route_edit',
                  // Keep for implementation of this page
                  // pre_page: 'dossier_form_insurance_route',
                  pre_page: 'dossier_form_coffin_and_product_route',
                  next_page: 'dossier_form_other_products_route',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'edit',
                component: TheDossierParticularitiesPage,
                name: 'dossier_form_particularities_route_edit',
                meta: {
                  display_type: 'edit',
                  switch_type_route: 'dossier_form_particularities_route',
                  // Keep for implementation of this page
                  // pre_page: 'dossier_form_insurance_route_edit',
                  pre_page: 'dossier_form_coffin_and_product_route_edit',
                  next_page: 'dossier_form_other_products_route_edit',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              }
            ]
          },
          {
            path: 'other-products',
            children: [
              {
                path: 'view',
                component: TheDossierOtherProductsPage,
                name: 'dossier_form_other_products_route',
                meta: {
                  display_type: 'view',
                  switch_type_route: 'dossier_form_other_products_route_edit',
                  pre_page: 'dossier_form_particularities_route',
                  next_page: 'dossier_form_external_services_route_edit',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'edit',
                component: TheDossierOtherProductsPage,
                name: 'dossier_form_other_products_route_edit',
                meta: {
                  display_type: 'edit',
                  switch_type_route: 'dossier_form_other_products_route',
                  pre_page: 'dossier_form_particularities_route_edit',
                  next_page: 'dossier_form_external_services_route_edit',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              }
            ]
          },
          {
            path: 'external-services',
            children: [
              {
                path: 'view',
                component: TheDossierExternalServicesPage,
                name: 'dossier_form_external_services_route',
                meta: {
                  display_type: 'view',
                  switch_type_route:
                    'dossier_form_external_services_route_edit',
                  pre_page: 'dossier_form_other_products_route',
                  // Keep for implementation of this page
                  // next_page: 'dossier_form_mourning_reception_route',
                  next_page: 'dossier_documents_router_route',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'edit',
                component: TheDossierExternalServicesPage,
                name: 'dossier_form_external_services_route_edit',
                meta: {
                  display_type: 'edit',
                  switch_type_route: 'dossier_form_external_services_route',
                  pre_page: 'dossier_form_other_products_route_edit',
                  // Keep for implementation of this page
                  // next_page: 'dossier_form_mourning_reception_route',
                  next_page: 'dossier_documents_router_route',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              }
            ]
          },
          {
            path: 'other-events',
            children: [
              {
                path: 'view',
                component: TheDossierOtherEventsPage,
                name: 'dossier_form_other_events_route',
                meta: {
                  display_type: 'view',
                  switch_type_route: 'dossier_form_other_events_route_edit',
                  pre_page: 'dossier_form_other_products_route',
                  // Keep for implementation of this page
                  // next_page: 'dossier_form_mourning_reception_route',
                  next_page: 'dossier_documents_router_route',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'edit',
                component: TheDossierOtherEventsPage,
                name: 'dossier_form_other_events_route_edit',
                meta: {
                  display_type: 'edit',
                  switch_type_route: 'dossier_form_other_events_route',
                  pre_page: 'dossier_form_external_services_route_edit',
                  // Keep for implementation of this page
                  // next_page: 'dossier_form_mourning_reception_route',
                  next_page: 'dossier_documents_router_route',
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              }
            ]
          },
          {
            path: 'mourning',
            component: TheDossierReceptionPage,
            name: 'dossier_form_mourning_reception_route',
            meta: {
              pre_page: 'dossier_form_other_products_route',
              next_page: 'dossier_form_obituary_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'obituary',
            component: TheDossierObituaryPage,
            name: 'dossier_form_obituary_route',
            meta: {
              pre_page: 'dossier_form_other_products_route',
              next_page: 'dossier_documents_router_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'documents',
            children: [
              {
                path: '',
                component: TheNewDossierDocumentsPage,
                props: true,
                name: 'dossier_documents_router_route'
              },
              {
                path: 'wikidocument/:wikiPlaceId',
                component: TheNewDossierDocumentsPage,
                props: true,
                name: 'dossier_wiki_documents_router_route'
              },
              {
                path: ':document_type',
                children: [
                  {
                    path: ':document_id/preview',
                    component: TheDossierDocumentShowPage,
                    name: 'dossier_form_document_preview_route',
                    props: true,
                    meta: {
                      isDossierPage: true
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'attachments',
            component: TheDossierAttachmentsPage,
            name: 'dossier_form_attachments_route',
            props: true,
            meta: {
              pre_page: 'dossier_documents_router_route',
              next_page: 'dossier_form_condolence_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'condolence',
            component: TheDossierCondolencePage,
            name: 'dossier_form_condolence_route',
            meta: {
              pre_page: 'dossier_form_attachments_route',
              next_page: 'dossier_invoicing_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'invoicing',
            component: TheDossierInvoicingRouter,
            name: 'dossier_invoicing_route',
            children: [
              {
                path: '',
                component: TheDossierInvoicingPage,
                name: 'dossier_form_invoicing_route',
                meta: {
                  isDossierPage: true
                }
              },
              {
                path: ':invoicing_id',
                component: InvoicingInfo,
                name: 'dossier_form_invoicing_show_route',
                props: true
              },
              {
                path: 'invoice/new',
                component: InvoicingForm,
                name: 'dossier_form_invoice_new_route',
                meta: { doc_type: 'INV' },
                props: true
              },
              {
                path: 'purchase-order/new',
                component: InvoicingForm,
                name: 'dossier_form_purchase_order_new_route',
                meta: { doc_type: 'SUPORD' },
                props: true
              },
              {
                path: 'quote/new',
                component: InvoicingForm,
                name: 'dossier_form_quote_new_route',
                meta: { doc_type: 'QUO' },
                props: true
              },
              {
                path: 'credit-note/new',
                component: InvoicingForm,
                name: 'dossier_form_credit_note_new_route',
                meta: { doc_type: 'CN' },
                props: true
              }
            ],
            meta: {
              pre_page: 'dossier_form_condolence_route',
              next_page: 'dossier_supplier_order_route',
              parent: 'edit_dossier'
            }
          },
          {
            path: 'supplier_order',
            component: TheDossierSupplierOrderPage,
            name: 'dossier_supplier_order_route',
            meta: {
              pre_page: 'dossier_invoicing_route',
              next_page: 'dossier_form_tasks_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'item-order',
            component: TheItemManagementPage,
            name: 'dossier_item_order_route',
            props: true,
            meta: {
              pre_page: 'dossier_invoicing_route',
              next_page: 'dossier_form_tasks_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'tasks',
            component: TheDossierTasksPage,
            name: 'dossier_form_tasks_route',
            meta: {
              pre_page: 'dossier_supplier_order_route',
              next_page: 'dossier_form_activities_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'activities',
            component: TheDossierActivitiesPage,
            name: 'dossier_form_activities_route',
            meta: {
              pre_page: 'dossier_form_activities_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'management',
            component: TheDossierSuperAdminManagementPage,
            name: 'dossier_form_management_route',
            meta: {
              pre_page: 'dossier_form_management_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'partner',
            // name: 'dossier_partner_route',
            // props: true,
            // component: TheDossierFormEditPage,
            children: [
              {
                path: 'cybille',
                component: TheDossierCybillePage,
                name: 'dossier_cybille_route',
                props: true
              },
              {
                path: 'enaos',
                component: TheDossierEnaosPage,
                name: 'dossier_enaos_route',
                props: true
              },
              {
                path: 'funebra',
                component: TheDossierFunebraPage,
                name: 'dossier_funebra_route',
                props: true
              },
              {
                path: 'fragment',
                component: TheDossierFragmentPage,
                name: 'dossier_fragment_route',
                props: true
              },
              {
                path: 'sorenir',
                component: TheDossierSorenirPage,
                name: 'dossier_sorenir_route',
                props: true
              },
              {
                path: 'legacio',
                component: TheDossierLegacioPage,
                name: 'dossier_legacio_route',
                meta: {
                  pre_page: null,
                  next_page: null,
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'heaven',
                component: TheDossierHeavenPage,
                name: 'dossier_heaven_route',
                meta: {
                  pre_page: null,
                  next_page: null,
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'fune-up',
                component: TheDossierFuneUpPage,
                name: 'dossier_fune_up_route',
                props: true
              },
              {
                path: 'kitty-for-relatives',
                component: TheDossierKittyForRelativesPage,
                name: 'dossier_kitty_for_relatives_route',
                meta: {
                  pre_page: null,
                  next_page: null,
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'iassure',
                component: TheDossierIAssurePage,
                name: 'dossier_iassure_route',
                meta: {
                  pre_page: null,
                  next_page: null,
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              },
              {
                path: 'mesange-prevoyance',
                component: TheDossierMesangePrevoyancePage,
                name: 'dossier_mesange_prevoyance_route',
                meta: {
                  pre_page: null,
                  next_page: null,
                  parent: 'edit_dossier',
                  isDossierPage: true
                }
              }
            ],
            meta: {
              type: 'edit'
            }
          },
          {
            path: 'publish-on-site',
            component: TheDossierSitePage,
            name: 'dossier_form_site_route',
            meta: {
              // pre_page: 'dossier_form_attachments_route',
              // next_page: 'dossier_invoicing_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'gallery',
            component: TheDossierPicturePage,
            name: 'dossier_gallery_page_route',
            children: [
              {
                path: '',
                name: 'dossier_gallery_list_route',
                component: TheGalleryPicturePage,
                props: true
              },
              {
                path: ':pictureId',
                children: [
                  {
                    path: '',
                    name: 'dossier_gallery_view_route',
                    component: TheGalleryPicturePage,
                    props: true
                  },
                  {
                    path: 'edit',
                    name: 'dossier_gallery_edit_route',
                    component: TheEditGalleryPicturePage,
                    props: true
                  },
                  {
                    path: 'ai',
                    name: 'dossier_gallery_ai_route',
                    component: TheAiPage,
                    props: true
                  }
                ]
              }
            ]
          },
          {
            path: 'picture',
            component: TheDossierPicturePage,
            name: 'dossier_picture_page_route',
            children: [
              {
                path: '',
                name: 'dossier_picture_preview_route',
                component: ThePreviewPicturePage
              },
              {
                path: 'picture-rectangle',
                name: 'edit_dossier_picture_rectangle',
                component: TheEditPicturePage,
                props: {
                  collection_name: 'picture_rectangle'
                }
              },
              {
                path: 'picture-square',
                name: 'edit_dossier_picture_square',
                component: TheEditPicturePage,
                props: {
                  collection_name: 'picture_square'
                }
              }
            ]
          },
          {
            path: 'screen',
            name: 'dossier_screen_route',
            component: TheScreenPage
          }
        ],
        meta: {
          type: 'edit',
          form: 'normal'
        }
      },
      {
        path: 'light/:id',
        name: 'dossier_light_edit_route',
        props: true,
        component: TheDossierLightFormEditPage,
        children: [
          {
            path: '',
            name: 'dossier_light_edit_route_base',
            redirect: { name: 'dossier_light_form_information_route' }
          },
          {
            path: 'information',
            component: TheDossierLightInformation,
            name: 'dossier_light_form_information_route',
            meta: {
              pre_page: null,
              next_page: 'dossier_light_form_contact_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'contact',
            component: TheDossierLightContactPage,
            name: 'dossier_light_form_contact_route',
            meta: {
              pre_page: 'dossier_light_form_information_route',
              next_page: 'dossier_light_form_remains_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'remains',
            component: TheDossierLightRemainsPage,
            name: 'dossier_light_form_remains_route',
            meta: {
              pre_page: 'dossier_light_form_contact_route',
              next_page: 'dossier_light_form_particularity_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'particularity',
            component: TheDossierParticularityLight,
            name: 'dossier_light_form_particularity_route',
            meta: {
              pre_page: 'dossier_light_form_remains_route',
              next_page: 'dossier_light_form_attachments_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          },
          {
            path: 'attachments',
            component: TheDossierLightAttachmentsPage,
            name: 'dossier_light_form_attachments_route',
            meta: {
              pre_page: 'dossier_light_form_particularity_route',
              next_page: 'dossier_light_form_information_route',
              parent: 'edit_dossier',
              isDossierPage: true
            }
          }
        ],
        meta: {
          type: 'edit',
          form: 'light'
        }
      }
    ]
  }
]
