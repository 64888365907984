<template>
  <div
    v-if="stockAlert.length > 0"
    class="flex shrink-0 flex-col gap-2 rounded-xl bg-gradient-to-br from-secondary-500 to-secondary-400 px-4 py-3"
  >
    <div
      class="flex shrink-0 flex-row items-center"
      :class="minimized ? 'cursor-pointer' : ''"
      @click="minimized ? (minimized = !minimized) : undefined"
    >
      <h2 class="flex-1 text-xl font-bold leading-tight capitalize-first">
        Alert
      </h2>
      <div v-if="!minimized" class="shrink-0">
        <button @click.stop="minimized = !minimized">
          <FontAwesomeIcon
            :icon="minimized ? 'chevron-down' : 'times'"
            size="lg"
          />
        </button>
      </div>
    </div>
    <div
      v-if="!minimized"
      class="grid-auto-rows-fr mb-1 grid flex-1 gap-2 overflow-y-auto md:grid-cols-3 lg:grid-cols-4"
    >
      <div
        v-for="item in stockAlertFiltered"
        :key="item.id"
        class="flex flex-col rounded border border-slate-700 bg-gray-100/75 p-1.5"
      >
        <div class="flex flex-row">
          <div v-if="item?.item" class="flex-1">
            <RouterLink
              :to="{
                name: 'stock_item_route',
                params: { itemId: item?.item?.id }
              }"
              class="block font-bold"
            >
              {{ item?.item?.name[$i18n.locale] }}
            </RouterLink>
            <small>
              # {{ item.location.site.name }} ({{ item.location.name }})
            </small>
          </div>
          <div class="shrink-0">
            <span class="rounded border border-slate-700 bg-white px-1">
              {{ item.count }} / {{ item.stock_limit }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions } from 'vuex'

export default {
  name: 'TheAlertComponent',
  components: { FontAwesomeIcon },
  props: {
    locationId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      minimized: false,
      stockAlert: []
    }
  },
  computed: {
    stockAlertFiltered() {
      return this.stockAlert.filter(
        (item) => !this.locationId || item.location_id === this.locationId
      )
    }
  },
  mounted() {
    this.getSummary()
  },
  methods: {
    ...mapActions({
      processGetSummaryAction: 'stock/getSummary'
    }),
    getSummary() {
      this.processGetSummaryAction().then((response) => {
        this.stockAlert = response.items_alert
      })
    }
  }
}
</script>

<style scoped></style>
