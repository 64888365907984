import { settingsFuneralManagerRoutes } from '@/router/settings/funeralManager'

const TheSettingsPage = () =>
  import(
    /* webpackChunkName: "group-settings" */ '../../views/settings/TheSettingsPage'
  )

import { settingsGeneralRoutes } from './general/index'
import { settingsUsersRoutes } from './user/index'

import { settingsCompanyRoutes } from './corporation/company/index'
import { settingsFuneralHomeRoutes } from './corporation/funeralHome/index'
import { settingsSiteRoutes } from './corporation/site/index'
import { settingsLocationRoutes } from './corporation/location/index'

import { settingsAgendaRoutes } from '@/router/settings/schedule/agenda'
import { settingsPlanningRoutes } from '@/router/settings/schedule/planning'
import { settingsResourcesRoutes } from '@/router/settings/schedule/resources'

import { settingsTaskRoutes } from './management/task/index'
import { settingsDocumentsRoutes } from './management/document/index'
import { settingsAutomationRoutes } from './management/automation/index'
import { settingsRoleRoutes } from './management/role/index'
import { settingsSurveyRoutes } from './management/survey/index'
import { settingsBankAccountRoutes } from './invoicing/bank_account/index'
import { settingsItemRoute } from './invoicing/item/index'
import { settingsItemCategoryRoutes } from '@/router/settings/invoicing/item_category'
import { settingsFuneralHomePartnerRoutes } from '@/router/settings/management/funeralHomePartner'
import { settingsListItemRoutes } from '@/router/settings/management/listItem'
import { settingsPackRoute } from '@/router/settings/invoicing/pack'
import { settingsMessageTextRoutes } from '@/router/settings/management/messageText'
import { settingsVariableRoutes } from '@/router/settings/management/variable'
import { settingsInvoicingCategoryRoutes } from '@/router/settings/invoicing/invoicing_category'
import { settingsSupplierRoutes } from '@/router/settings/invoicing/supplier'
import { settingsMaterialResourceRoutes } from '@/router/settings/corporation/materialResource'
import { settingsAddressRoutes } from '@/router/settings/corporation/address'
import { settingsAccountingDiaryRoutes } from '@/router/settings/invoicing/accountingDiary'
import { settingsAccountingCodeRoutes } from '@/router/settings/invoicing/accountingCode'
import { settingsScreenSettingsRoutes } from '@/router/settings/management/screenSettings'
import {settingsPaymentModeRoutes} from "@/router/settings/invoicing/PaymentMode";

export const settingsSubPages = [
  {
    path: '/settings',
    name: 'settings_index_route',
    component: TheSettingsPage,
    children: [
      //  settings

      ...settingsGeneralRoutes,
      ...settingsUsersRoutes,

      //#region Corporation
      ...settingsCompanyRoutes,
      ...settingsAddressRoutes,
      ...settingsFuneralHomeRoutes,
      ...settingsSiteRoutes,
      ...settingsLocationRoutes,
      ...settingsMaterialResourceRoutes,
      //#endregion

      //#region schedule
      ...settingsAgendaRoutes,
      ...settingsPlanningRoutes,
      ...settingsResourcesRoutes,
      //#endregion

      //#region management
      ...settingsScreenSettingsRoutes,
      ...settingsTaskRoutes,
      ...settingsAutomationRoutes,
      ...settingsSurveyRoutes,
      ...settingsRoleRoutes,
      ...settingsFuneralHomePartnerRoutes,
      ...settingsListItemRoutes,
      ...settingsDocumentsRoutes,
      ...settingsMessageTextRoutes,
      ...settingsVariableRoutes,
      //#endregion

      //#region invoicing
      ...settingsBankAccountRoutes,
      ...settingsPaymentModeRoutes,
      ...settingsItemRoute,
      ...settingsPackRoute,
      ...settingsSupplierRoutes,
      ...settingsItemCategoryRoutes,
      ...settingsInvoicingCategoryRoutes,
      ...settingsAccountingDiaryRoutes,
      ...settingsAccountingCodeRoutes,
      //#endregion

      //#region funeralmanager
      ...settingsFuneralManagerRoutes
      //#endregion
    ]
  }
]
