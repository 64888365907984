<template>
  <TransitionRoot as="template" :show="localeIsModalOpen" class="z-100">
    <Dialog as="div" class="relative z-10" @close="handleClose">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-blue-100 bg-opacity-75 transition-opacity dark:bg-slate-700 dark:bg-opacity-75"
        />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel
                class="pointer-events-auto relative w-screen"
                :class="modalWidth"
              >
                <div
                  class="flex h-screen max-h-screen flex-col space-y-3 bg-white pb-4 pt-5 shadow-xl dark:bg-slate-700 dark:text-white"
                >
                  <div
                    class="flex shrink-0 flex-row justify-between align-baseline"
                    :class="padding"
                  >
                    <div>
                      <DialogTitle
                        class="text-xl font-bold text-gray-900 capitalize-first dark:text-white"
                      >
                        {{ title }}
                      </DialogTitle>
                      <slot name="subtitle" />
                    </div>
                    <div
                      v-if="hideCloseButton"
                      class="ml-3 flex h-7 items-center"
                    >
                      <button
                        type="button"
                        class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none dark:bg-slate-700"
                        @click="handleClose"
                      >
                        <span class="absolute -inset-2.5" />
                        <span class="sr-only">Close panel</span>
                        <FontAwesomeIcon :icon="['fal', 'times']" size="xl" />
                      </button>
                    </div>
                  </div>
                  <div class="flex min-h-0 flex-1" :class="padding">
                    <slot name="body" />
                  </div>
                  <div
                    v-if="!hideCloseButton || !$slots.cta"
                    class="flex shrink-0 flex-row items-baseline justify-between gap-x-3"
                    :class="padding"
                  >
                    <div v-if="!hideCloseButton" class="shrink-0">
                      <BaseButton
                        ref="ref_close"
                        :title="
                          $t(
                            closeLabelAsCancel
                              ? 'button.cancel'
                              : 'button.close'
                          )
                        "
                        icon="times"
                        theme-color="bg-secondary text-contrast-secondary dark:border dark:border-secondary dark:hover:bg-secondary-900 dark:bg-slate-700 dark:text-secondary"
                        @click="handleClose"
                      />
                    </div>
                    <div
                      class="flex flex-1 flex-row items-center justify-end gap-x-3"
                    >
                      <slot name="cta" />
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseSlideOverModal',
  components: {
    FontAwesomeIcon,
    BaseButton,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot
  },
  props: {
    isModalOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    footer: {
      type: Boolean,
      required: false,
      default: true
    },
    width: {
      type: String,
      required: false,
      default: 'md',
      validator: function (value) {
        return ['md', 'xl', '2xl'].indexOf(value) !== -1
      }
    },
    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false
    },
    closeLabelAsCancel: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['close', 'update:isModalOpen'],
  computed: {
    modalWidth() {
      switch (this.width) {
        case '4xl':
          return 'max-w-4xl'
        case '3xl':
          return 'max-w-3xl'
        case '2xl':
          return 'max-w-2xl'
        case 'xl':
          return 'max-w-xl'
        case 'md':
          return 'max-w-md'
        case 'xs':
          return 'max-w-xs'
      }
    },
    padding() {
      switch (this.width) {
        case 'xs':
          return 'px-4 sm:px-3'
        default:
          return 'px-4 sm:px-6'
      }
    },
    localeIsModalOpen: {
      get() {
        return this.isModalOpen
      },
      set(value) {
        this.$emit('update:isModalOpen', value)
      }
    }
  },
  methods: {
    handleClose() {
      this.localeIsModalOpen = false
      this.$emit('close')
    }
  }
}
</script>
