<template>
  <div
    class="flex w-full flex-col gap-2 rounded-lg border border-slate-300 bg-white p-3 shadow-lg"
  >
    <div class="flex flex-row">
      <div class="flex-1 text-lg font-bold leading-none">
        {{ this.place?.name[this.$i18n.locale] }}
      </div>
      <span class="shrink-0">
        <RouterLink
          :to="{
            name: 'route_base_map'
          }"
          class="text-slate-500"
        >
          <FontAwesomeIcon :icon="['fas', 'times-circle']" size="lg" />
        </RouterLink>
      </span>
    </div>
    <template v-if="place">
      <div class="flex flex-row justify-between">
        <div>
          <BaseButton
            v-if="gravePlots.length"
            is-outline
            icon="tombstone"
            :title="`${gravePlots.length} ${$t(
              gravePlots.length > 1
                ? 'attributes.concessions'
                : 'attributes.concession'
            )}`"
            icon-type="fas"
            size="small"
            @click=""
            inverse-icon-order
          />
        </div>
        <div class="flex flex-row gap-2">
          <BaseButton
            v-if="place.maptile_key?.length"
            is-outline
            icon="satellite"
            icon-type="fas"
            size="small"
            @click="$emit('display-tiles', place.maptile_key)"
          />
          <BaseButton
            is-outline
            icon="crosshairs-simple"
            icon-type="fas"
            size="small"
            @click="
              $emit('set-center', {
                longitude: this.place.address.longitude,
                latitude: this.place.address.latitude,
                zoom: 16
              })
            "
          />
        </div>
      </div>
      <div
        class="flex flex-col divide-y rounded-md border bg-white text-sm font-medium"
      >
        <div v-if="!loading && place.address" class="p-2 capitalize">
          <h4 class="text-xs leading-tight text-gray-500">
            {{ $t('attributes.address') }}
          </h4>
          <div class="leading-tight">
            {{ place.address.street }}<br />{{ place.address.zip }}
            {{ place.address.city }}
          </div>
        </div>
        <div v-if="!loading && place.phone_field" class="p-2 capitalize">
          <h4 class="text-xs leading-tight text-gray-500">
            {{ $t('attributes.phone') }}
          </h4>
          <div class="leading-tight">
            {{ place.phone_field }}
          </div>
        </div>
        <div v-if="!loading && place.fax_field" class="p-2 capitalize">
          <h4 class="text-xs leading-tight text-gray-500">
            {{ $t('attributes.fax') }}
          </h4>
          <div class="leading-tight">
            {{ place.fax_field }}
          </div>
        </div>
        <div v-if="!loading && place.email" class="p-2 capitalize">
          <h4 class="text-xs leading-tight text-gray-500">
            {{ $t('attributes.email') }}
          </h4>
          <div class="leading-tight">
            {{ place.email }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import gravePlot from '@/store/modules/gravePlot'

export default {
  name: 'MapSidePlace',
  components: { BaseButton, FontAwesomeIcon, FontAwesomeLayers },
  props: {
    placeId: {
      type: String,
      required: true
    }
  },
  emits: ['set-center', 'display-tiles'],
  data() {
    return {
      place: null,
      gravePlots: [],
      loading: false
    }
  },
  watch: {
    placeId(newValue, oldValue) {
      console.log('placeId', newValue)
      if (newValue !== oldValue) {
        this.getMarkerInfo()
      }
    }
  },
  mounted() {
    if (this.placeId) {
      this.getMarkerInfo()
    }
  },
  computed: {
    nameComputed() {
      return this.place?.name[this.$i18n.locale] ?? this.name
    }
  },
  methods: {
    ...mapActions({
      processGetMarkerInfoAction: 'wiki/getMarkerInfo'
    }),
    getMarkerInfo() {
      this.loading = true
      this.processGetMarkerInfoAction({ place_id: this.placeId })
        .then((response) => {
          this.place = response.place
          this.gravePlots = response.grave_plots
          this.$emit('set-center', {
            longitude: this.place.address.longitude,
            latitude: this.place.address.latitude,
            zoom: 16
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
